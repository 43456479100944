import { Navigate, createBrowserRouter } from "react-router-dom"
import Root from "./Root"
import NotFound from "../pages/NotFound"
import InventoryPage from "../pages/InventoryPage/InventoryPage"
import CalendarPage from "../pages/CalendarPage"
import RecapPage from "../pages/recapPage/RecapPage"
import SettingsPage from "../pages/SettingsPage"
import { Admin } from "../pages/admin"
import { StoresPage } from "../pages/admin/StoresPage"
import { RedirectToStorePage } from "../pages/admin/RedirectToStorePage"
import PermissionsProtectedRoute from "./PermissionsProtectedRoute"
import CallbackRoute from "./CallbackRoute"
import { LogoutPage } from "../pages/LogoutPage"
import { CategoriesPage } from "../pages/CategoriesPage"
import { InventoryRoot } from "../components/inventory/InventoryRoot"
import { RedirectToPage } from "../pages/RedirectToPage"
import { StoreScheduler } from "../pages/admin/StoreScheduler"
import { UsersPage } from "@/pages/UsersPage"
import { EngagementPromoPage } from "@/pages/engagementPromoPage/EngagementPromoPage"
import { PerformancesPage } from "@/pages/admin/PerformancesPage/PerformancesPage"
import { NoStoreLinkedPage } from "@/pages/NoStoreLinkedPage"
import { HomePage } from "@/pages/HomePage"
import { MessagesPage } from "@/pages/admin/MessagesPage/MessagesPage"
import { RegroupingIdaPage } from "@/pages/admin/MatchingPage/RegroupingIdaPage"
import { CompaniesPage } from "@/pages/admin/CompaniesPage"
import { GlobalPerformancesPage } from "@/pages/admin/PerformancesPage/pages/GlobalPerformancesPage"
import { RuptureGlobalPerformancesPage } from "@/pages/admin/PerformancesPage/pages/RuptureGlobalPerformancesPage"
import { PredictionPerformancesPage } from "@/pages/admin/PerformancesPage/pages/PredictionPerformancesPage"
import { InventoryPerformancesPage } from "@/pages/admin/PerformancesPage/pages/InventoryPerformancesPage"
import { PredictedCaVsSalesPerformancesPage } from "@/pages/admin/PerformancesPage/pages/PredictedCaVsSalesPerformancesPage"
import { InventoryProvider } from "@/contexts/InventoryContext"
import InventoriesAdmin from "@/pages/admin/PerformancesPage/components/inventories/InventoriesAdmin"
import { TopUnorderedReferencesChart } from "@/pages/admin/PerformancesPage/components/rupture/TopUnorderedReferencesChart"
import ModificationsChartByStore from "@/pages/admin/PerformancesPage/components/inventories/ModificationsChartByStore"
import OrderDashPage from "@/pages/admin/PerformancesPage/pages/OrderDashPage"
import PotentiallyRupturesAdmin from "@/pages/admin/PerformancesPage/components/rupture/PotentiallyRupturesAdmin"
import { CAPerformancesPage } from "@/pages/admin/PerformancesPage/components/performance/CAPerformancesPage"
import OrdersRegroupingTable from "@/pages/admin/MatchingPage/orders_regrouping_components/ordersRegroupingTable"
import UnmatchedReferencesTable from "@/pages/admin/MatchingPage/matching_dashboard_components/unmatchedReferencesTable"
import { MatchReferencesSalesPage } from "@/pages/admin/MatchingPage/sale_regrouping_components/MatchReferencesSalesPage"
import BreakagesWeeklyEvolutionDashboard from "@/pages/admin/PerformancesPage/components/breakages/BreakageWeeklyEvolutionPage"
import { MatchingPage } from "@/pages/MatchingPage"

const router = createBrowserRouter([
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "callback",
    element: <CallbackRoute />,
  },
  {
    path: "no-store-linked",
    element: <NoStoreLinkedPage />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <RedirectToPage to="/inventory" />,
      },
      {
        path: "home",
        element: <HomePage />,
      },
      {
        path: "recap",
        element: <RedirectToPage to="/inventory/recap" />,
      },
      {
        path: "inventory",
        element: <InventoryRoot />,
        children: [
          {
            path: "",
            element: (
              <InventoryProvider>
                <InventoryPage />
              </InventoryProvider>
            ),
          },
          {
            path: "recap",
            element: <RecapPage />,
          },
        ],
      },
      {
        path: "matching",
        element: <MatchingPage />,
      },
      {
        path: "engagement-promo",
        element: <EngagementPromoPage />,
      },
      {
        path: "account/*",
        element: <Navigate to="/home" />,
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },
      {
        path: "categories",
        element: <CategoriesPage />,
      },
      {
        path: "admin",
        element: (
          <PermissionsProtectedRoute permissions={["arolya:company:admin"]}>
            <Admin />
          </PermissionsProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="companies" />,
          },
          {
            path: "users",
            element: (
              <PermissionsProtectedRoute permissions={["ida:admin"]}>
                <UsersPage />
              </PermissionsProtectedRoute>
            ),
          },
          {
            path: "companies",
            children: [
              {
                path: ":companyId",
                element: <StoresPage />,
              },
              {
                path: ":companyId/store-scheduler",
                element: <StoreScheduler />,
              },
              {
                path: ":companyId/regrouping-ida",
                element: <RegroupingIdaPage />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="matching" />,
                  },
                  {
                    path: "matching",
                    element: <UnmatchedReferencesTable />,
                  },
                  {
                    path: "orders-regrouping",
                    element: <OrdersRegroupingTable />,
                  },
                  {
                    path: "sales-matching",
                    element: <MatchReferencesSalesPage />,
                  },
                ],
              },
              {
                path: ":companyId/performances",
                element: <PerformancesPage />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="taux-de-modification" />,
                  },
                  {
                    path: "taux-de-modification",
                    element: <OrderDashPage />,
                  },
                  {
                    path: "breakage",
                    element: <BreakagesWeeklyEvolutionDashboard />,
                  },
                  {
                    path: "performance-board-ca",
                    element: <CAPerformancesPage />,
                  },
                  {
                    path: "global",
                    element: <GlobalPerformancesPage />,
                  },
                  {
                    path: "rupture-global",
                    element: <RuptureGlobalPerformancesPage />,
                    children: [
                      { path: "", element: <Navigate to="by-store" /> },
                      {
                        path: "by-store",
                        element: <TopUnorderedReferencesChart />,
                      },
                      {
                        path: "potentially",
                        element: <PotentiallyRupturesAdmin />,
                      },
                    ],
                  },
                  {
                    path: "prediction",
                    element: <PredictionPerformancesPage />,
                  },
                  {
                    path: "inventory",
                    element: <InventoryPerformancesPage />,
                    children: [
                      { path: "", element: <Navigate to="by-store" /> },
                      {
                        path: "level-stock",
                        element: <InventoriesAdmin />,
                      },
                      {
                        path: "by-store",
                        element: <ModificationsChartByStore />,
                      },
                    ],
                  },
                  {
                    path: "predicted-ca-vs-sales",
                    element: <PredictedCaVsSalesPerformancesPage />,
                  },
                ],
              },
              {
                path: ":companyId/messages",
                element: <MessagesPage />,
              },
              {
                path: "",
                element: <CompaniesPage />,
              },
            ],
          },
        ],
      },
      {
        path: "stores/:storeId",
        element: (
          <PermissionsProtectedRoute permissions={["arolya:company:admin"]}>
            <RedirectToStorePage />
          </PermissionsProtectedRoute>
        ),
      },
    ],
  },
])

export default router
