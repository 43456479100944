import { cn } from "@/lib/utils"
import { PropsWithChildren, ReactNode } from "react"
import { Img } from "react-image"
import { imageLoader } from "./ImageLoader"
import { twJoin } from "tailwind-merge"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

export const InventoryRowLayout = ({
  internalReferenceElement,
  badgesElement,
  isDanger,
  isActive,
  isWarning,
  tableElement,
  imageSource,
  onImageClick,
  productName,
  children,
  typology,
  isSticky = false,
  className,
  isGreyed,
}: PropsWithChildren<{
  imageSource?: string | null
  isWarning: boolean
  isDanger: boolean
  isActive: boolean
  onImageClick: () => void
  productName: string
  badgesElement: ReactNode
  tableElement: ReactNode
  internalReferenceElement: ReactNode
  isSticky?: boolean
  className?: string
  typology?: string | null
  isGreyed?: boolean
}>) => {
  const { storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  return (
    <div className="relative">
      <div
        className={cn(
          "border border-slate-200/90 bg-white rounded-xl px-2 pt-2 pb-4 lg:pb-3 flex-1 z-10 flex flex-col gap-1 p-3",
          isWarning && "border-2 border-yellow-600",
          isDanger && " border-2 border-red-600",
          !isActive && "opacity-20",
          className,
        )}
      >
        <div className="flex flex-col lg:flex-row gap-3">
          <div
            className={twJoin(
              "h-fit flex-[4]",
              isSticky && "lg:sticky lg:top-3",
            )}
          >
            <div className="flex gap-1 sm:gap-2 items-center">
              <button
                className={twJoin(
                  "w-12 h-12 sm:w-16 sm:h-16 bg-gray-100 rounded-lg",
                  isGreyed && "opacity-20",
                )}
                onClick={onImageClick}
              >
                {imageSource && (
                  <Img
                    src={imageSource}
                    className={cn(
                      "w-12 h-12 sm:w-16 sm:h-16 sm:min-w-16 sm:min-h-16 rounded-lg border border-slate-200 object-cover",
                    )}
                    loader={imageLoader}
                  />
                )}
              </button>
              <div className="space-y-1">
                <h2 className="tracking-tight font-bold text-primary/80 uppercase line-clamp-1">
                  {typology && storeSettings?.show_typology
                    ? `${typology} - ${productName}`
                    : productName}
                </h2>
                {badgesElement}
                {internalReferenceElement}
              </div>
            </div>
            <div className={twJoin("flex mt-2", isGreyed && "opacity-20")}>
              {tableElement}
            </div>
          </div>
          <div className="space-y-2 ">{children}</div>
        </div>
      </div>
    </div>
  )
}
