import { ReactNode, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { type DispatchActionType, type StateType } from "../../../types"
import { toFixedNumber } from "../../../utils/toFixedNumber"
import { InventoryType } from "../../../utils/__generated__/graphql"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { getQuarterValue } from "../../../utils/getQuarterValue"
import { InventoryInputMobile } from "../../../ui/InventoryInputs/InventoryInputMobile"
import { SelectorLabel } from "../../../ui/SelectorLabel"
import { InventoryInputDesktop } from "../../../ui/InventoryInputs/InventoryInputDesktop"
import { RingColor, SelectorData } from "../../../ui/InventoryInputs/utils"
import { ExtraElement } from "./ExtraElements/ExtraElement"
import { getDisplayedUnit } from "@/utils/getUnit"
import { useInventoryContext } from "@/contexts/InventoryContext"

interface InventoryInputProps {
  stock_too_high_flag?: boolean | undefined | null
  stockAecouler?: boolean | undefined | null
  stock_too_low_flag?: boolean | undefined | null
  stock_to_verify_flag?: boolean | undefined | null
  isPrecommande?: boolean
  isLineaireUpdatedTo0?: boolean | undefined | null
  prediction_uncertain?: boolean | undefined | null
  mercurialeId: string
  unit: string
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
  name: InventoryType
  value: number | null
  disabled?: boolean
  updateInventory: (
    value: string,
    selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
  ) => Promise<void>
  isNewReference?: boolean
  floorInventoryQuantity?: number
  setLastInputSelected?: () => void
  potentialRuptureToVerify?: boolean
  isBreakage?: boolean
  isPopoverTriggerable?: boolean
  extraOrderInputSection?: ReactNode
}

export function InventoryInput({
  prediction_uncertain,
  stock_too_high_flag,
  stock_too_low_flag,
  isLineaireUpdatedTo0 = false,
  stock_to_verify_flag,
  stockAecouler,
  isPrecommande = false,
  mercurialeId,
  unit,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
  potentialRuptureToVerify,
  isBreakage,
  value,
  name,
  disabled = false,
  updateInventory,
  isNewReference = false,
  floorInventoryQuantity,
  setLastInputSelected,
  isPopoverTriggerable,
  extraOrderInputSection,
}: InventoryInputProps): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const { storeSettings, companyName, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const numericKeypadValue = useSelector(
    (state: StateType) => state.userInterfaceReducer.numericPadValue,
  )
  const { lastInputSelected } = useInventoryContext()

  const computedValue = toFixedNumber(value ?? 0)

  const parsedNumericKeypadValue = useMemo(() => {
    const parsedValue = parseFloat(numericKeypadValue)
    if (
      lastInputSelected?.type !== name ||
      lastInputSelected?.mercurialeId !== mercurialeId ||
      isNaN(parsedValue)
    )
      return
    return parsedValue
  }, [
    mercurialeId,
    name,
    numericKeypadValue,
    lastInputSelected?.mercurialeId,
    lastInputSelected?.type,
  ])

  const displayedUnit = getDisplayedUnit(
    storeSettings?.use_kg_pce === true ? unit : "cs",
    companyName,
    storeFranchise,
  )

  const { isMD } = useWindowSize()

  const ringColor: RingColor | undefined = useMemo(() => {
    if (stock_to_verify_flag) return "red"
    if (potentialRuptureToVerify) return "red"
    if (stockAecouler) return "softOrange"
    if (stock_too_high_flag) return "orange"
    if (stock_too_low_flag) return "lightOrange"
    if (
      isNewReference &&
      (parsedNumericKeypadValue ?? computedValue) === 0 &&
      value === null
    ) {
      return "yellow"
    }
    if (isPrecommande) return "blue"
    if (prediction_uncertain) return "lightRed"
  }, [
    computedValue,
    isNewReference,
    isPrecommande,
    parsedNumericKeypadValue,
    prediction_uncertain,
    stockAecouler,
    stock_to_verify_flag,
    potentialRuptureToVerify,
    stock_too_high_flag,
    stock_too_low_flag,
    value,
  ])

  const selectorData = useMemo<SelectorData<number>[] | undefined>(() => {
    if (name !== InventoryType.Shelf || computedValue <= 0) return

    const fullValue = getQuarterValue(computedValue, "size-4")
    const threeQuartersValue = getQuarterValue(computedValue * 0.75, "size-4")
    const halfValue = getQuarterValue(computedValue * 0.5, "size-4")
    const oneQuarterValue = getQuarterValue(computedValue * 0.25, "size-4")

    return [
      {
        label: (
          <SelectorLabel
            text="PLEIN"
            value={fullValue.value}
            icon={fullValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="PLEIN" />,
        value: (fullValue.value ?? 0) + (fullValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="75%"
            value={threeQuartersValue.value}
            icon={threeQuartersValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="75%" />,
        value:
          (threeQuartersValue.value ?? 0) + (threeQuartersValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="50%"
            value={halfValue.value}
            icon={halfValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="50%" />,
        value: (halfValue.value ?? 0) + (halfValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="25%"
            value={oneQuarterValue.value}
            icon={oneQuarterValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="25%" />,
        value: (oneQuarterValue.value ?? 0) + (oneQuarterValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="VIDE"
            value={0}
            unit={displayedUnit}
            displayZero
          />
        ),
        selectedLabel: <SelectorLabel text="VIDE" />,
        value: 0,
      },
    ]
  }, [computedValue, displayedUnit, name])

  const selectorValue = useMemo<number | undefined>(() => {
    return selectorData
      ?.map((data) => data.value)
      .reduce((acc, curr) =>
        Math.abs(curr - (floorInventoryQuantity ?? 0)) <
        Math.abs(acc - (floorInventoryQuantity ?? 0))
          ? curr
          : acc,
      )
  }, [floorInventoryQuantity, selectorData])

  return isMD ? (
    <InventoryInputDesktop
      name={`${name}-${mercurialeId}`}
      value={
        stock_to_verify_flag === true
          ? -1
          : name === InventoryType.Shelf &&
              floorInventoryQuantity !== undefined &&
              floorInventoryQuantity !== computedValue
            ? floorInventoryQuantity
            : (parsedNumericKeypadValue ?? computedValue)
      }
      onChange={(value) => {
        void updateInventory(value, {
          type: name,
          mercurialeId,
        })
      }}
      onFocus={() => {
        setLastInputSelected?.()
        dispatch({
          type: "setSelectedInventory",
          payload: {
            type: name,
            mercurialeId,
          },
        })
      }}
      selected={
        lastInputSelected?.type === name &&
        lastInputSelected.mercurialeId === mercurialeId
      }
      disabled={disabled}
      exponentNumber={name === InventoryType.Shelf ? computedValue : undefined}
      ringColor={ringColor}
      selectorData={selectorData}
      selectorValue={selectorValue}
      onSelectorChange={(selectedValue) => {
        void updateInventory(`${selectedValue}`, {
          type: InventoryType.Floor,
          mercurialeId: mercurialeId ?? "",
        })
      }}
      topElement={
        <ExtraElement
          inventoryType={name}
          value={computedValue}
          ringColor={ringColor}
          unit={displayedUnit}
          stockAecouler={stockAecouler ?? false}
          floorInventoryQuantity={floorInventoryQuantity}
          isToVerify={stock_to_verify_flag ?? false}
          isTooHigh={stock_too_high_flag ?? false}
          isTooLow={stock_too_low_flag ?? false}
          isPrecommande={
            (isPrecommande ?? false) &&
            storeSettings?.block_order_on_precommande !== true
          }
          isLineaireUpdatedTo0={isLineaireUpdatedTo0 ?? false}
          waitedQuantityOrdered={waitedQuantityOrdered}
          promoWaitedQuantityOrdered={promoWaitedQuantityOrdered}
          isEngagementPromo={
            (isPrecommande ?? false) &&
            (storeSettings?.block_order_on_precommande ?? false)
          }
          isPotentialRupture={potentialRuptureToVerify}
        />
      }
      isBreakage={isBreakage}
      isPotentialRupture={potentialRuptureToVerify}
      isPopoverTriggerable={isPopoverTriggerable}
      extraOrderInputSection={extraOrderInputSection}
    />
  ) : (
    <InventoryInputMobile
      name={`${name}-${mercurialeId}`}
      value={
        stock_to_verify_flag === true
          ? -1
          : name === InventoryType.Shelf &&
              floorInventoryQuantity !== undefined &&
              floorInventoryQuantity !== computedValue
            ? floorInventoryQuantity
            : (parsedNumericKeypadValue ?? computedValue)
      }
      onChange={(value) => {
        void updateInventory(value, {
          type: name,
          mercurialeId,
        })
      }}
      onFocus={() => {
        setLastInputSelected?.()
        dispatch({
          type: "setSelectedInventory",
          payload: {
            type: name,
            mercurialeId,
          },
        })
      }}
      unit={displayedUnit}
      disabled={disabled}
      selected={
        lastInputSelected?.type === name &&
        lastInputSelected.mercurialeId === mercurialeId
      }
      ringColor={ringColor}
      selectorValue={selectorValue}
      onSelectorChange={(selectedValue) => {
        void updateInventory(`${selectedValue}`, {
          type: InventoryType.Floor,
          mercurialeId: mercurialeId ?? "",
        })
      }}
      selectorData={selectorData}
      bottomElement={
        <ExtraElement
          inventoryType={name}
          value={computedValue}
          ringColor={ringColor}
          unit={displayedUnit}
          floorInventoryQuantity={floorInventoryQuantity}
          stockAecouler={stockAecouler ?? false}
          isToVerify={stock_to_verify_flag ?? false}
          isTooHigh={stock_too_high_flag ?? false}
          isLineaireUpdatedTo0={isLineaireUpdatedTo0 ?? false}
          isTooLow={stock_too_low_flag ?? false}
          isPrecommande={
            (isPrecommande ?? false) &&
            storeSettings?.block_order_on_precommande !== true
          }
          waitedQuantityOrdered={waitedQuantityOrdered}
          promoWaitedQuantityOrdered={promoWaitedQuantityOrdered}
          isEngagementPromo={
            (isPrecommande ?? false) &&
            (storeSettings?.block_order_on_precommande ?? false)
          }
          isPotentialRupture={potentialRuptureToVerify}
        />
      }
      isPopoverTriggerable={isPopoverTriggerable}
    />
  )
}
