import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from "@apollo/client"
import {
  AllMercurialInfo,
  UpdateReferencesAction,
  UpdatedReference,
} from "../reducers/mercurialReducer"
import { StoreReducerState } from "../reducers/storeReducer"
import { UserInterfaceReducerState } from "../reducers/userInterfaceReducer"
import { DispatchActionType } from "../types"
import {
  BatchUpdateInput,
  BatchUpdateMutation,
  Exact,
  InventoryType,
} from "./__generated__/graphql"
import ReactGA from "react-ga4"
import { DataSynchronizationStatus } from "../reducers/connectionReducer"
import { getOrderQuantity } from "./getOrderQuantity"
import { getBatchData } from "./getBatchData"
import { captureException } from "@sentry/react"
import {
  computeModificationObject,
  sendLogsModificationUpdateParams,
} from "./sendLogsModificationUpdate"

interface SendEventToGAParams {
  selectedInventory: UserInterfaceReducerState["selectedInventory"]
  mercuriale: Partial<AllMercurialInfo> | undefined
  updatedReference: UpdatedReference
  value: string
  storeId: string | null
}

function sendEventToGA({
  selectedInventory,
  updatedReference,
  mercuriale,
  value,
  storeId,
}: SendEventToGAParams) {
  let previousValue: number | null = null
  switch (selectedInventory?.type) {
    case InventoryType.Back:
      previousValue =
        updatedReference?.backInventoryQuantity ??
        mercuriale?.back_inventory_qty ??
        null
      break
    case InventoryType.Floor:
      previousValue =
        updatedReference?.floorInventoryQuantity ??
        mercuriale?.floor_inventory_qty ??
        null
      break
    case InventoryType.Shelf:
      previousValue =
        updatedReference?.shelfFloorSize ?? mercuriale?.shelf_floor_size ?? null
      break
    case InventoryType.Order:
      if (mercuriale !== undefined && !("quantity_actual" in mercuriale)) break
      previousValue =
        updatedReference?.orderInventoryQuantity ??
        mercuriale?.quantity_actual ??
        null
      break
  }

  ReactGA.event("modification", {
    previousValue,
    event_category: selectedInventory?.type ?? "Other",
    nextValue: Number(value),
    dimMercurialeId: mercuriale?.dim_mercuriale_id,
    orderId: mercuriale?.order_id,
    saleId: mercuriale?.sale_id,
    storeId,
  })
}

// Store all timeouts in a global variable with mercuriale id as key (each timeout will be deleted at the end of the function)
const timeouts: Record<string, NodeJS.Timeout> = {}

interface handleChangeParams {
  value: string
  selectedInventory: UserInterfaceReducerState["selectedInventory"]
  mercurialeInfos: Partial<AllMercurialInfo>[]
  updatedReferences: Record<string, UpdatedReference>
  storeSettings: StoreReducerState["storeSettings"]
  dispatch: DispatchActionType
  batchUpdateMutation: (
    options?:
      | MutationFunctionOptions<
          BatchUpdateMutation,
          Exact<{ input: BatchUpdateInput }>,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<FetchResult<BatchUpdateMutation>>
  online: boolean
  isTestMode: boolean
  storeId: string | null
  dimOrderRequestId: string | undefined
  logsModificationUpdate: sendLogsModificationUpdateParams["logsModificationUpdate"]
}

export async function handleKeyboard({
  value,
  selectedInventory,
  mercurialeInfos,
  updatedReferences,
  storeSettings,
  dispatch,
  batchUpdateMutation,
  online,
  isTestMode,
  storeId,
  dimOrderRequestId,
  logsModificationUpdate,
}: handleChangeParams): Promise<void> {
  const mercurialeId = selectedInventory?.mercurialeId
  if (
    typeof mercurialeId !== "string" ||
    typeof selectedInventory?.type !== "string" ||
    (!/^\d+$/.test(value) && !/^\d+\.\d+$/.test(value))
  )
    return
  const mercuriale = mercurialeInfos.find(
    (mercuriale) => mercuriale.mercuriale_id === mercurialeId,
  )
  if (mercuriale === undefined) return
  const filteredMercuriales = mercurialeInfos.filter(
    (mercurialeInfo) => mercurialeInfo.sale_id === mercuriale?.sale_id,
  )
  const newUpdatedReferences: UpdateReferencesAction["payload"] =
    filteredMercuriales.map((mercurialeInfo) => {
      return {
        ...(updatedReferences[mercurialeInfo.mercuriale_id!] ?? {}),
        mercurialeId: mercurialeInfo.mercuriale_id!,
      }
    })

  // Compute equivalent of value in kg/pce to have a reference value for other references
  const equivalent =
    storeSettings?.use_kg_pce === true
      ? Number(value ?? 0)
      : Number(value ?? 0) * (mercuriale.colisage ?? 1)

  switch (selectedInventory?.type) {
    case InventoryType.Back:
    case InventoryType.Floor:
    case InventoryType.Shelf: {
      newUpdatedReferences.forEach((newUpdatedReference, i) => {
        const filteredMercuriale = filteredMercuriales[i]
        if (selectedInventory?.type === InventoryType.Back) {
          newUpdatedReferences[i].backInventoryQuantity =
            storeSettings?.use_kg_pce === true
              ? Number(value)
              : equivalent / (filteredMercuriale.colisage ?? 1)
        }
        if (selectedInventory?.type === InventoryType.Floor) {
          newUpdatedReferences[i].floorInventoryQuantity =
            storeSettings?.use_kg_pce === true
              ? Number(value)
              : equivalent / (filteredMercuriale.colisage ?? 1)
        }
        if (selectedInventory?.type === InventoryType.Shelf) {
          newUpdatedReferences[i].shelfFloorSize =
            storeSettings?.use_kg_pce === true
              ? Number(value)
              : equivalent / (filteredMercuriale.colisage ?? 1)
          newUpdatedReferences[i].floorInventoryQuantity =
            newUpdatedReferences[i].shelfFloorSize
        }
        if (selectedInventory?.type === InventoryType.Shelf) {
          newUpdatedReferences[i].shelfFloorSizeUpdatedAt =
            new Date().toISOString()
        }
        if (
          newUpdatedReference?.isOrderInventoryQuantityUpdated !== true &&
          "quantity_predicted_array" in filteredMercuriale &&
          Array.isArray(filteredMercuriale?.quantity_predicted_array)
        ) {
          const orderQuantity = getOrderQuantity({
            backQuantity:
              newUpdatedReference.backInventoryQuantity ??
              filteredMercuriale.back_inventory_qty ??
              0,
            floorQuantity:
              newUpdatedReference.floorInventoryQuantity ??
              filteredMercuriale.floor_inventory_qty ??
              0,
            predictedQuantityArray:
              filteredMercuriale.quantity_predicted_array ?? [],
          })
          newUpdatedReferences[i].orderInventoryQuantity = orderQuantity
          if ((newUpdatedReferences[i].orderInventoryQuantity ?? 0) < 0) {
            newUpdatedReferences[i].orderInventoryQuantity = 0
          }
        }
        if (
          selectedInventory?.type === InventoryType.Back ||
          selectedInventory?.type === InventoryType.Floor ||
          selectedInventory?.type === InventoryType.Shelf
        ) {
          newUpdatedReferences[i].stock_too_high_flag = false
          newUpdatedReferences[i].stock_too_low_flag = false
        }
        if (selectedInventory?.type === InventoryType.Back) {
          newUpdatedReferences[i].stock_to_verify_flag = false
        }
        // If user set shelf floor size to 0, set order inventory quantity to 0
        if (
          selectedInventory.type === InventoryType.Shelf &&
          newUpdatedReferences[i].shelfFloorSize === 0
        ) {
          newUpdatedReferences[i].orderInventoryQuantity = 0
        }
      })
      break
    }
    case InventoryType.Order: {
      newUpdatedReferences.forEach((_, i) => {
        newUpdatedReferences[i].time_rupture_flag_verified = true
      })
      const newUpdatedReferenceIndex = newUpdatedReferences.findIndex(
        (newUpdatedReference) =>
          newUpdatedReference.mercurialeId === mercurialeId,
      )

      newUpdatedReferences[newUpdatedReferenceIndex].orderInventoryQuantity =
        Number(value)
      newUpdatedReferences[
        newUpdatedReferenceIndex
      ].isOrderInventoryQuantityUpdated = true
      break
    }
  }
  dispatch({
    type: "updateReferences",
    payload: newUpdatedReferences,
  })

  clearTimeout(timeouts[mercurialeId])
  timeouts[mercurialeId] = setTimeout(() => {
    const newUpdatedReferenceIndex = newUpdatedReferences.findIndex(
      (newUpdatedReference) =>
        newUpdatedReference.mercurialeId === mercurialeId,
    )
    const newUpdatedReference = newUpdatedReferences[newUpdatedReferenceIndex]
    if (newUpdatedReference === undefined) return

    sendEventToGA({
      selectedInventory,
      updatedReference: newUpdatedReference,
      mercuriale,
      value,
      storeId,
    })
    if (
      newUpdatedReferences[newUpdatedReferenceIndex]
        ?.isOrderInventoryQuantityUpdated !== true
    ) {
      newUpdatedReferences[newUpdatedReferenceIndex].orderInventoryQuantity =
        undefined
    }

    if (
      storeSettings?.use_kg_pce === true &&
      selectedInventory.type === InventoryType.Order
    ) {
      const valueInCs = Math.ceil(Number(value) / (mercuriale.colisage ?? 1))
      newUpdatedReferences[newUpdatedReferenceIndex].orderInventoryQuantity =
        valueInCs * (mercuriale.colisage ?? 1)
      newUpdatedReferences[
        newUpdatedReferenceIndex
      ].isOrderInventoryQuantityUpdated = true

      dispatch({
        type: "updateReference",
        payload: newUpdatedReferences[newUpdatedReferenceIndex],
      })
      dispatch({
        type: "setNumericPadValueAction",
        payload: "",
      })
    }

    if (online === false && isTestMode === false) {
      dispatch({
        type: "setDataSynchronizationStatus",
        payload: DataSynchronizationStatus.UNSYNCHRONIZED,
      })
    }
    const saveModifications = async (): Promise<void> => {
      const modification = computeModificationObject({
        inventoryType: selectedInventory.type!,
        mercurialeInfo: mercuriale,
        updatedReference: updatedReferences[mercurialeId],
        modifiedValue: Number(value),
      })

      try {
        const updatedRefsForBatch = Object.fromEntries(
          newUpdatedReferences.map((ref) => [ref.mercurialeId, ref]),
        )
        const batchData = getBatchData(mercurialeInfos, updatedRefsForBatch)
        const batchUpdateResult = await batchUpdateMutation({
          variables: {
            input: {
              batch_data: batchData,
              dim_order_request_id:
                "dim_order_request_id" in mercuriale
                  ? (dimOrderRequestId ?? mercuriale.dim_order_request_id)
                  : null,
              store_id: storeId ?? "",
              inventory_type: selectedInventory?.type,
            },
          },
        })

        if (batchUpdateResult.data?.batchUpdate?.error !== null) {
          throw batchUpdateResult.data?.batchUpdate?.error
        }
        if (
          typeof batchUpdateResult.data.batchUpdate.dim_order_request_id ===
          "string"
        ) {
          dispatch({
            type: "setDimOrderRequestId",
            payload: {
              dimMercurialeId: mercuriale.dim_mercuriale_id ?? "",
              dimOrderRequestId:
                batchUpdateResult.data?.batchUpdate.dim_order_request_id,
            },
          })
        }

        const logsModificationResult = await logsModificationUpdate({
          variables: {
            input: {
              modifications_logs_items: [modification],
              store_id: storeId!,
            },
          },
        })
        if (
          logsModificationResult.data?.logsModificationUpdate?.error !== null
        ) {
          throw logsModificationResult.data?.logsModificationUpdate?.error
        }
      } catch (error) {
        console.error(error)
        const errorMesssage =
          error instanceof Error ? error.message : "Données non sauvegardées"
        captureException(new Error(errorMesssage))
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.FAILURE,
        })
        dispatch({
          type: "addModification",
          payload: modification,
        })
      }
    }
    if (online === true && isTestMode === false) {
      void saveModifications()
    }
    if (
      online === false &&
      isTestMode === false &&
      selectedInventory.type !== undefined
    ) {
      const modification = computeModificationObject({
        inventoryType: selectedInventory.type,
        modifiedValue: Number(value),
        mercurialeInfo: mercuriale,
        updatedReference: updatedReferences[mercurialeId],
      })
      if (!modification) return
      dispatch({
        type: "addModification",
        payload: modification,
      })
    }
    // Delete timeout to avoid memory leaks
    delete timeouts[mercurialeId]
  }, 500)
}
