import React from "react"
import { TrophyIcon, BadgePercent, TrashIcon } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { Paysan } from "@/ui/icons/Paysan"

export interface OrderRecord {
  store_name?: string
  order_request_date?: string
  expected_reception_date?: string
  sale_name_ida?: string
  sale_name?: string
  promotion?: boolean
  stock_too_low_flag?: boolean
  stock_too_high_flag?: boolean
  local_flag?: boolean
  high_breakage_flag?: boolean
  breakage_percentage?: number
  colisage?: number
  quantity_sold_in_colis?: number
  total_inventory_qty?: number
  quantity_predicted_in_colis?: number
  final_quantity_predicted_in_colis?: number
  quantity_ordered_in_colis?: number
  impact?: number
  modification_types?: string
  sale_id: string
  // Add other properties as needed
}

interface OrderTableProps {
  records: OrderRecord[]
  topSaleIds: Set<string>
  showPredAvantStock: boolean
  showImpactColumn: boolean
  handleSort: (columnKey: string) => void
  renderSortIndicator: (columnKey: string) => React.ReactNode
}

const typeDisplayMap: Record<string, string | null> = {
  NO_ISSUES: null,
  OVERSUPPLY_AVOIDED: "Surstock évité",
  BREAKAGE_AVOIDED: "Demarque évitée",
  CAUSED_STOCKOUT: "Rupture provoquée",
  STOCKOUT_AVOIDED: "Rupture évitée",
  CAUSED_OVERSUPPLY: "Surstock provoqué",
  CAUSED_BREAKAGE: "Demarque provoquée",
}

function getBadgeVariant(modType: string): "destructive" | "outline" {
  return modType.includes("CAUSED") ? "destructive" : "outline"
}

function truncateText(text: string, maxLength: number): string {
  return text.length > maxLength ? text.slice(0, maxLength - 3) + "..." : text
}

export default function OrderTable({
  records,
  topSaleIds,
  showPredAvantStock,
  showImpactColumn,
  handleSort,
  renderSortIndicator,
}: OrderTableProps) {
  const headers = [
    {
      key: "store",
      label: "Infos Commande",
      alignment: "text-left",
      extraClasses: "",
    },
    {
      key: "reference",
      label: "Référence",
      alignment: "text-left",
      extraClasses: "",
    },
    {
      key: "ventes",
      label: "Ventes",
      alignment: "text-right",
      extraClasses: "hidden md:table-cell",
    },
    {
      key: "stocks",
      label: "Stocks",
      alignment: "text-right",
      extraClasses: "hidden md:table-cell",
    },
    {
      key: "predAvt",
      label: "Préd. avt stock",
      alignment: "text-right",
      extraClasses: "hidden lg:table-cell",
      condition: showPredAvantStock,
    },
    {
      key: "predCmd",
      label: "Préd./Cmds.",
      alignment: "text-right",
      extraClasses: "",
    },
    {
      key: "diff",
      label: "Diff (cs)",
      alignment: "text-right",
      extraClasses: "hidden sm:table-cell",
    },
    {
      key: "impact",
      label: "Impact",
      alignment: "text-right",
      extraClasses: "hidden sm:table-cell",
      condition: showImpactColumn,
    },
  ]

  return (
    <div className="overflow-auto">
      <table className="min-w-full text-sm border table-auto">
        <thead className="bg-gray-50 border-b text-gray-700">
          <tr>
            {headers.map((header, index) => {
              if (header.condition === false) return null
              const classes = `px-3 py-2 whitespace-nowrap cursor-pointer ${header.alignment} ${header.extraClasses}`
              return (
                <th
                  key={index}
                  className={classes}
                  onClick={() => handleSort(header.key)}
                  role="button"
                  aria-sort="none"
                >
                  {header.label} {renderSortIndicator(header.key)}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {records.map((rec, idx) => {
            const isTopSale = topSaleIds.has(rec.sale_id)
            const breakagePct = rec.breakage_percentage ?? 0
            const diffVal =
              (rec.quantity_ordered_in_colis ?? 0) -
              (rec.final_quantity_predicted_in_colis ?? 0)
            const displayDiff =
              diffVal > 0 ? `+${diffVal.toFixed(2)}` : diffVal.toFixed(2)
            const diffClass =
              diffVal > 0
                ? "text-red-600"
                : diffVal < 0
                  ? "text-orange-600"
                  : "text-gray-600"
            const rowClass = rec.high_breakage_flag
              ? "border-2 border-red-500"
              : "border-b last:border-0 hover:bg-gray-50"

            // Compute predicted and commande values
            const predicted =
              Math.round((rec.final_quantity_predicted_in_colis ?? 0) * 100) /
              100
            const commande =
              Math.round((rec.quantity_ordered_in_colis ?? 0) * 100) / 100

            const shouldStrike =
              rec.modification_types && predicted !== commande

            return (
              <tr key={idx} className={rowClass}>
                <td className="bg-white px-3 py-2 whitespace-nowrap">
                  <div className="font-semibold">{rec.store_name}</div>
                  <div className="text-[11px] text-gray-600">
                    Réception: {rec.expected_reception_date?.slice(0, 10)}
                  </div>
                </td>
                <td className="px-2 py-1 whitespace-nowrap">
                  <div>
                    <span className="font-semibold text-sm">
                      {truncateText(rec.sale_name ?? "inconnu", 30)}
                    </span>
                  </div>
                  <div className="text-[10px] text-gray-600">
                    {rec.sale_name_ida && (
                      <span>
                        Code: <strong>{rec.sale_name_ida}</strong>
                      </span>
                    )}
                    {rec.colisage !== undefined && (
                      <span>
                        {" | Colisage: "}
                        <strong>{rec.colisage}</strong>
                      </span>
                    )}
                  </div>
                  <div className="mt-0.5 flex items-center gap-1">
                    {rec.promotion && (
                      <span
                        className="inline-flex items-center gap-1 px-1 py-0.5 text-[9px] rounded-full bg-blue-100 text-black"
                        title="Promo"
                      >
                        <BadgePercent className="w-3 h-3" />
                      </span>
                    )}
                    {isTopSale && (
                      <span
                        className="inline-flex items-center gap-1 px-1 py-0.5 text-[9px] font-semibold bg-gradient-to-r from-amber-100 to-amber-200 text-amber-800"
                        title="Top"
                      >
                        <TrophyIcon className="w-3 h-3" />
                      </span>
                    )}
                    {rec.stock_too_low_flag && (
                      <span
                        className="inline-flex items-center gap-1 px-1 py-0.5 text-[9px] rounded-full bg-orange-300 text-white"
                        title="Stock bas"
                      >
                        Stock bas
                      </span>
                    )}
                    {rec.stock_too_high_flag && (
                      <Badge
                        variant="destructive"
                        className="text-[9px] px-1 py-[2px]"
                        title="Stock élevé"
                      >
                        Stock élevé
                      </Badge>
                    )}
                    {rec.local_flag && (
                      <span
                        className="flex items-center text-[9px] gap-1 bg-green-700 px-1 py-0.5 rounded text-white"
                        title="Local"
                      >
                        <Paysan className="w-3 h-3" />
                      </span>
                    )}
                    {rec.high_breakage_flag && (
                      <span
                        className="inline-flex items-center gap-1 text-[9px] border border-red-600 px-1 py-0.5 rounded text-red-600"
                        title={`Démarque élevée: ${breakagePct.toFixed(1)}%`}
                      >
                        <TrashIcon className="w-3 h-3" />
                        {breakagePct.toFixed(1)}%
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-3 py-2 text-right hidden md:table-cell whitespace-nowrap">
                  {Math.round((rec.quantity_sold_in_colis ?? 0) * 100) / 100} cs
                </td>
                <td className="px-3 py-2 text-right hidden md:table-cell whitespace-nowrap">
                  {Math.round((rec.total_inventory_qty ?? 0) * 100) / 100} cs
                </td>
                {showPredAvantStock && (
                  <td className="px-3 py-2 text-right hidden lg:table-cell whitespace-nowrap">
                    {Math.round((rec.quantity_predicted_in_colis ?? 0) * 100) /
                      100}{" "}
                    cs
                  </td>
                )}
                <td className="px-3 py-2 text-right whitespace-nowrap">
                  {shouldStrike ? (
                    <>
                      <span className="line-through text-gray-500">
                        {predicted} cs
                      </span>
                      <span className="ml-2 font-bold">{commande} cs</span>
                    </>
                  ) : (
                    <span>{predicted} cs</span>
                  )}
                </td>
                <td className="px-3 py-2 text-right whitespace-nowrap hidden sm:table-cell">
                  <span className={diffClass}>{displayDiff}</span>
                </td>
                {showImpactColumn &&
                  ((rec.quantity_sold_in_colis ?? 0) > 0 ? (
                    <td className="px-3 py-2 text-right whitespace-nowrap">
                      <div className="flex flex-col items-end">
                        {rec.modification_types &&
                          (() => {
                            // Split and clean the modification types string.
                            const mods = rec.modification_types
                              .replace(/[[\]]/g, "")
                              .split(",")
                              .map((s) => s.trim())
                              .filter(Boolean)

                            // Check if there is any negative modification (destructive).
                            const destructiveMods = mods.filter((mod) =>
                              mod.includes("CAUSED"),
                            )

                            // If there are destructive mods, show only those; otherwise, show all.
                            const modsToDisplay =
                              destructiveMods.length > 0
                                ? destructiveMods
                                : mods

                            return (
                              <div className="mt-1 flex flex-wrap gap-1 justify-end">
                                {modsToDisplay.map((mod, idx) => {
                                  const label = typeDisplayMap[mod]
                                  if (!label) return null
                                  return (
                                    <Badge
                                      key={idx}
                                      variant={getBadgeVariant(mod)}
                                      className="text-[10px] px-1 py-[2px]"
                                    >
                                      {label}
                                    </Badge>
                                  )
                                })}
                              </div>
                            )
                          })()}
                      </div>
                    </td>
                  ) : (
                    <td className="px-3 py-2 text-right whitespace-nowrap">
                      <Badge
                        variant="outline"
                        className="text-[10px] px-1 py-[2px]"
                      >
                        En attente
                      </Badge>
                    </td>
                  ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
