import {
  Dispatch,
  FormEventHandler,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../styles/inventory-page.css"
import InventoryNav from "./InventoryNav"

import { type DispatchActionType, type StateType } from "../../types"
import { type AllMercurialInfo } from "../../reducers/mercurialReducer"
import NewLoading from "../loading/NewLoading"
import { Sidebar } from "./Sidebar"
import { InventorySort } from "./InventorySort"
import { ItemContent, VirtuosoHandle } from "react-virtuoso"
import { Button } from "../../ui/Button"
import { ChevronUpIcon } from "@heroicons/react/24/outline"
import { useWindowSize } from "../../hooks/useWindowSize"
import { MobileInventoryRecap } from "./MobileInventoryRecap"
import { useOutletContext } from "react-router-dom"
import { InventoryContext } from "./InventoryRoot"
import { InventoryTable } from "./InventoryTable"
import { removeDuplicatesValues } from "@/utils/removeDuplicates"
import { CheckinModals, InventoryModals } from "./modals/InventoryModals"
import { Input } from "@/components/ui/input"
import { SearchIcon } from "lucide-react"
import { SuppliersFilter } from "@/components/inventory/SuppliersFilter"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { CheckinPage } from "@/pages/checkinPage"
import { SortOption } from "@/reducers/userInterfaceReducer"
import { useInventoryContext } from "@/contexts/InventoryContext"
import { SaveDataModalState } from "./modals/RetrySave/RetrySaveModal"

type Data = Partial<AllMercurialInfo>[]

interface InventoryPageTemplateProps {
  scrollSectionRenderer: (index: number) => ReactNode
  page: "inventoryPage"
  loading?: boolean
  rawData: Data
  dataLength: number
  rowContent: ItemContent<unknown, unknown>
  updateInventory: (value: string) => Promise<void>
  searchedAmount: number
  filteredAmount: number
  unfilteredAmount: number
  currentIndex: number | null
  virtuosoRef: RefObject<VirtuosoHandle>
  isMercurialeModalOpen: boolean
  setIsMercurialeModalOpen: Dispatch<SetStateAction<boolean>>
  oldState: React.MutableRefObject<{
    sortList?: string[]
    sortOption?: SortOption
    selectedDimMercurialeId?: string
  } | null>
  deduplicateFilteredMercurialeInfos: Data
  filteredMercurialeInfos: Data
}

export function InventoryPageTemplate({
  virtuosoRef,
  page,
  loading = false,
  rawData,
  dataLength,
  rowContent,
  updateInventory,
  searchedAmount,
  filteredAmount,
  unfilteredAmount,
  currentIndex,
  isMercurialeModalOpen,
  setIsMercurialeModalOpen,
  oldState,
  scrollSectionRenderer,
  deduplicateFilteredMercurialeInfos,
  filteredMercurialeInfos,
}: InventoryPageTemplateProps): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const [{ synchronisationLoading }] = useOutletContext<InventoryContext>()
  const companyName = useSelector(
    (state: StateType) => state.storeReducer.companyName,
  )

  const { storeSettings, storeSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const {
    searchTerm,
    displayShelfFloorSize,
    suppliersIds,
    familyName,
    subFamilyName,
    filteredReferences,
  } = useSelector(
    (state: StateType) => state.userInterfaceReducer.inventoryPage,
  )
  const previousSearchTerm = useRef(searchTerm)

  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )

  const { mercurialAndStoreInventories, inactiveReferences } = useSelector(
    (state: StateType) => state.mercurialReducer,
  )

  const selectedDimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )

  const checkInModalCloseDate = useSelector((state: StateType) =>
    selectedDimMercurialeId !== undefined
      ? state.mercurialReducer.checkInModalCloseDates[selectedDimMercurialeId]
      : undefined,
  )

  const { isMD, isLG } = useWindowSize()

  const [isOfflineRefreshModalOpen, setIsOfflineRefreshModalOpen] =
    useState(false)
  const [isDataSynchronizeModalOpen, setIsDataSynchronizeModalOpen] =
    useState(false)
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  const [saveDataModalState, setSaveDataModalState] =
    useState<SaveDataModalState>({ isOpen: false })
  const [isOrderReceptionDateModalOpen, setIsOrderReceptionDateModalOpen] =
    useState(false)

  // Prevent refesh when iternet connection is offline
  useEffect(() => {
    const beforeUnloadListener: EventListenerOrEventListenerObject = (
      event,
    ) => {
      if (window.navigator.onLine) return
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = false
    }

    window.addEventListener("beforeunload", beforeUnloadListener)

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener)
    }
  }, [])

  const handleSearchTerm: FormEventHandler<HTMLInputElement> = (event) => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      offset: -50,
    })
    dispatch({
      type: "setSearchBar",
      payload: { page, searchTerm: event.currentTarget.value },
    })
  }

  const resetScroll = useCallback(
    () => virtuosoRef.current?.scrollToIndex({ index: 0, offset: -50 }),
    [virtuosoRef],
  )

  useEffect(() => {
    if (
      previousSearchTerm.current !== "" &&
      searchTerm === "" &&
      currentIndex !== null
    ) {
      virtuosoRef.current?.scrollToIndex({
        index: currentIndex ?? 0,
        align: "start",
        offset: -50,
      })
    }

    previousSearchTerm.current = searchTerm
  }, [searchTerm, currentIndex, virtuosoRef])

  const { lastInputSelected } = useInventoryContext()

  useEffect(() => {
    if (
      lastInputSelected.saleIdIndex !== null &&
      typeof lastInputSelected.saleIdIndex === "number"
    ) {
      virtuosoRef.current?.scrollToIndex({
        index: lastInputSelected.saleIdIndex,
        align: "start",
        offset: (lastInputSelected.index ?? 0) * 200,
        behavior: "smooth",
      })
    }
  }, [lastInputSelected, virtuosoRef])

  useEffect(() => {
    if ((isMD && !isLG) || !displayShelfFloorSize) return
    dispatch({
      type: "toggleDisplayShelfFloorSize",
      payload: { page: "inventoryPage" },
    })
  }, [dispatch, displayShelfFloorSize, isLG, isMD, page])

  const hiddenReferences = useMemo(() => {
    if (
      suppliersIds === undefined &&
      familyName === undefined &&
      subFamilyName === undefined
    ) {
      return unfilteredAmount - filteredAmount
    }
    return searchedAmount - filteredAmount
  }, [
    familyName,
    filteredAmount,
    searchedAmount,
    subFamilyName,
    suppliersIds,
    unfilteredAmount,
  ])
  const mercurialeDate = rawData?.[0]?.date_integration

  const notAvailableProducts = mercurialAndStoreInventories.filter(
    (mercuriale) => inactiveReferences.includes(mercuriale.mercuriale_id ?? ""),
  )

  const notAvailableProductsWithoutDuplicates = removeDuplicatesValues(
    notAvailableProducts,
    "mercuriale_id",
  )

  const isValidOrderDate = useMemo(() => {
    if (loading) return true
    const currentDate = formatDateToYYYYMMDD(new Date())
    return mercurialeDate === currentDate
  }, [loading, mercurialeDate])

  const suppliers = useMemo(() => {
    if (storeSuppliers === null) return []
    const deduplicateSuppliers = removeDuplicatesValues(storeSuppliers, "id")
    const mercurialeInfos = mercurialAndStoreInventories.filter(
      (mercurialInfo) =>
        filteredReferences === undefined ||
        filteredReferences.length === 0 ||
        filteredReferences.includes(mercurialInfo.mercuriale_id ?? ""),
    )

    const existingInInventoriesSuppliers = deduplicateSuppliers.filter(
      (supplier) =>
        mercurialeInfos.some((item) => item.supplier_id === supplier.id),
    )

    return existingInInventoriesSuppliers.map((supplier) => ({
      label: supplier.supplier_name,
      value: supplier.id,
    }))
  }, [filteredReferences, mercurialAndStoreInventories, storeSuppliers])

  const handleSelectSupplier = (supplierId: string) =>
    dispatch({
      type: "setFilters",
      payload: {
        page: "inventoryPage",
        supplierId,
      },
    })

  const [activeIndex, setActiveIndex] = useState(0)

  const hasToCheckin = useMemo(() => {
    if (!isValidOrderDate || storeSettings?.show_checkin_modal !== true) {
      return false
    }

    if (checkInModalCloseDate === undefined) return true

    if (getDiffBetweenDates(new Date(), new Date(checkInModalCloseDate)) < 0)
      return true

    return false
  }, [
    checkInModalCloseDate,
    isValidOrderDate,
    storeSettings?.show_checkin_modal,
  ])

  const [isCheckinPage, setIsCheckinPage] = useState(hasToCheckin)

  useEffect(() => {
    if ((dimMercuriales ?? [])?.length < 2 || loading) {
      setIsMercurialeModalOpen(false)
      return
    }
    setIsMercurialeModalOpen(true)
  }, [dimMercuriales, setIsMercurialeModalOpen, loading])

  return isCheckinPage ? (
    <>
      <CheckinModals
        isOrderReceptionDateModalOpen={isOrderReceptionDateModalOpen}
        isOfflineRefreshModalOpen={isOfflineRefreshModalOpen}
        setIsOrderReceptionDateModalOpen={setIsOrderReceptionDateModalOpen}
        setIsRefreshModalOpen={setIsRefreshModalOpen}
        isLoading={loading}
        isMercurialeModalOpen={isMercurialeModalOpen}
        setIsMercurialeModalOpen={setIsMercurialeModalOpen}
        oldState={oldState}
        dimMercuriales={dimMercuriales}
      />
      <CheckinPage setIsCheckinPage={setIsCheckinPage} />
    </>
  ) : (
    <>
      <InventoryModals
        isLoading={loading}
        saveDataModalState={saveDataModalState}
        isOfflineRefreshModalOpen={isOfflineRefreshModalOpen}
        isDataSynchronizeModalOpen={isDataSynchronizeModalOpen}
        isRefreshModalOpen={isRefreshModalOpen}
        setSaveDataModalState={setSaveDataModalState}
        setIsOfflineRefreshModalOpen={setIsOfflineRefreshModalOpen}
        setIsDataSynchronizeModalOpen={setIsDataSynchronizeModalOpen}
        setIsRefreshModalOpen={setIsRefreshModalOpen}
        setIsOrderReceptionDateModalOpen={setIsOrderReceptionDateModalOpen}
        updateInventory={updateInventory}
        isOrderReceptionDateModalOpen={isOrderReceptionDateModalOpen}
        hasToCheckin={hasToCheckin}
        dimMercuriales={dimMercuriales}
        oldState={oldState}
        setIsMercurialeModalOpen={setIsMercurialeModalOpen}
        isMercurialeModalOpen={isMercurialeModalOpen}
      />
      <div className="h-svh mh:h-screen relative bg-gray-40 overflow-hidden flex flex-col">
        <InventoryNav
          page={page}
          isLoading={synchronisationLoading}
          isMercurialeLoading={loading}
          onCategoryChange={resetScroll}
          onRefreshButtonClick={() => setIsOfflineRefreshModalOpen(true)}
          setSaveDataModalState={setSaveDataModalState}
        />
        <main className="flex-1 h-full flex flex-col relative z-0">
          {/* Page title & actions */}
          <div className="flex flex-1 justify-between h-full relative">
            {/* Left Column */}
            <div className="flex-1 flex flex-col lg:gap-1 relative">
              <div
                className={`z-20 top-0 left-0 w-full origin-top bg-gray-40 flex flex-col gap-2 items-center p-2 xl:px-4 transition-all duration-500`}
              >
                <div className="w-full">
                  <div className="flex-1 flex gap-2">
                    <InventorySort
                      page={page}
                      onSort={() => virtuosoRef.current?.scrollTo({ top: 0 })}
                    />
                    {storeSettings?.show_suppliers && (
                      <SuppliersFilter
                        className="md:flex"
                        onSelect={handleSelectSupplier}
                        suppliers={suppliers}
                        selectedIds={suppliersIds}
                      />
                    )}
                    <div className="relative flex gap-2 flex-1">
                      <SearchIcon className="w-4 h-4 absolute left-3 top-3 text-muted-foreground" />
                      <Input
                        onChange={handleSearchTerm}
                        className="pl-9"
                        placeholder={`Chercher`}
                        value={searchTerm}
                      />
                    </div>
                    {scrollSectionRenderer(activeIndex)}
                  </div>
                </div>
                <div className="flex justify-end w-full">
                  <p className="hidden lg:inline md:w-[80px] xl:w-[90px] text-[#323232] text-xs font-bold">
                    RÉSERVE
                  </p>
                  <p className="hidden lg:inline w-[90px] text-[#323232] text-xs font-bold">
                    RAYON
                  </p>
                  <p className="hidden lg:inline w-[120px] xl:w-[190px] text-[#323232] text-xs font-bold">
                    TOTAL
                  </p>
                  <p className="hidden lg:inline text-[#323232] text-xs font-bold mr-4">
                    COMMANDE
                  </p>
                </div>
              </div>
              <div className="h-full w-full">
                {loading ? (
                  <NewLoading />
                ) : (
                  <InventoryTable
                    onRangeChange={setActiveIndex}
                    ref={virtuosoRef}
                    totalCount={dataLength}
                    footer={() => (
                      <div className="flex justify-center pt-2 pb-40 md:pb-12">
                        {hiddenReferences > 0 && (
                          <button
                            className="underline"
                            onClick={() => {
                              if ((filteredReferences ?? []).length > 0) {
                                dispatch({
                                  type: "setFilteredReferences",
                                  payload: {
                                    page: "inventoryPage",
                                    filteredReferences: [],
                                  },
                                })
                                return
                              }
                              if (
                                suppliersIds === undefined &&
                                familyName === undefined &&
                                subFamilyName === undefined
                              ) {
                                dispatch({
                                  type: "setSearchBar",
                                  payload: {
                                    page: "inventoryPage",
                                    searchTerm: "",
                                  },
                                })
                                return
                              }
                              dispatch({
                                type: "setFilters",
                                payload: {
                                  page: "inventoryPage",
                                  supplierId: undefined,
                                  familyName: undefined,
                                  subFamilyName: undefined,
                                },
                              })
                            }}
                          >
                            {hiddenReferences} référence
                            {hiddenReferences > 1 ? "s" : ""} cachée
                            {hiddenReferences > 1 ? "s" : ""} par les filtres
                            actuels
                          </button>
                        )}
                      </div>
                    )}
                    itemContent={rowContent}
                  />
                )}
              </div>
            </div>
            {/* Right column */}
            <div className="hidden md:block w-[280px] h-full relative">
              <Sidebar
                mercurialeInfos={filteredMercurialeInfos}
                deduplicateFilteredMercurialeInfos={
                  deduplicateFilteredMercurialeInfos
                }
                notAvailableProducts={notAvailableProductsWithoutDuplicates}
                updateInventory={updateInventory}
                setIsOrderReceptionDateModalOpen={
                  setIsOrderReceptionDateModalOpen
                }
                className="mt-2 mr-2 gap-2"
                companyName={companyName ?? ""}
                previousOrderInventoryComputeDate={
                  mercurialAndStoreInventories.find(
                    (mercurialeInfo) =>
                      typeof mercurialeInfo.previous_order_inventory_compute_date ===
                      "string",
                  )?.previous_order_inventory_compute_date ?? undefined
                }
              />
            </div>
          </div>
          <section className="absolute bottom-0 z-20 w-full md:w-[calc(100%-280px)] pointer-events-none">
            <div className="flex justify-end p-2">
              <Button
                className="w-10 h-10 lg:w-8 lg:h-8 rounded-full p-0 bg-white hover:bg-slate-100 shadow text-green-500 pointer-events-auto"
                onClick={() => {
                  virtuosoRef.current?.scrollTo({ top: 0 })
                }}
              >
                <ChevronUpIcon className="w-6 h-6 lg:w-5 lg:h-5" />
              </Button>
            </div>
            {!isMD && !loading && (
              <MobileInventoryRecap
                mercurialeDate={mercurialeDate ?? undefined}
                isLoading={synchronisationLoading}
                setSaveDataModalState={setSaveDataModalState}
              />
            )}
          </section>
        </main>
      </div>
    </>
  )
}
