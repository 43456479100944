import { useEffect, useMemo } from "react"
import { useForm, type SubmitHandler, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, type StateType } from "../types"
import {
  Category,
  PdfFilter,
  useUpdateStoreSettingsMutation,
} from "../utils/__generated__/graphql"
import { useNavigate } from "react-router-dom"
import { defaultMercurialReducerState } from "../reducers/mercurialReducer"
import { defaultUserInterfaceReducerState } from "../reducers/userInterfaceReducer"
import { Select, type SelectOptions } from "../ui/Select"
import { Link } from "react-router-dom"
import { captureException } from "@sentry/react"
import { usePermissions } from "../hooks/usePermissions"
import { toast } from "sonner"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import Header from "@/components/header"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import { Download, Mail, RefreshCcw, Save, TriangleAlert } from "lucide-react"
import { InventoryButtonBottomFooter } from "@/components/inventoryButtonBottomFooter"

const pdfFilterOptions: SelectOptions<PdfFilter> = [
  {
    label: "Défaut",
    value: PdfFilter.Default,
  },
  {
    label: "Alphabétique",
    value: PdfFilter.Alphabetical,
  },
  {
    label: "Mercuriale",
    value: PdfFilter.Mercuriale,
  },
]

const useKGPCEOptions: SelectOptions<boolean> = [
  {
    label: "Caisses (cs)",
    value: false,
  },
  {
    label: "Kilos et pièces (kg et pce)",
    value: true,
  },
]

interface StoreSettingsForm {
  show_suppliers: boolean
  show_categories: boolean
  use_kg_pce: boolean
  pdf_filter: PdfFilter
  categories_orders: Category[]
  view_sales_first: boolean
  view_pa: boolean
  view_pv: boolean
  edit_pv: boolean
  display_purchase_code: boolean
  view_local: boolean
  show_checkin_modal: boolean
  show_breakage_confirm_order: boolean
  show_low_sales_confirm_order: boolean
  display_sale_name_ida: boolean
  limit_order_for_high_breakage: boolean
  show_out_of_stock: boolean
  show_linear_capacity: boolean
  show_price_modifications: boolean
  show_best_sales_elsewhere: boolean
  show_last_pv: boolean
  auto_scroll_inventory: boolean
  show_typology: boolean
  show_new_references_first: boolean
  show_promotions_first: boolean
}

function SettingsPage(): JSX.Element {
  const storeReducer = useSelector((state: StateType) => state.storeReducer)
  const connectionReducer = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const mercurialReducer = useSelector(
    (state: StateType) => state.mercurialReducer,
  )
  const trainingModeReducer = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const userInterfaceReducer = useSelector(
    (state: StateType) => state.userInterfaceReducer,
  )
  const userReducer = useSelector((state: StateType) => state.userReducer)

  const [updateStoreSettings, { loading }] = useUpdateStoreSettingsMutation()
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const { isIdaAdmin, isReadOnly } = usePermissions()

  const { handleSubmit, control, setValue, watch } = useForm<StoreSettingsForm>(
    {
      defaultValues: {
        show_suppliers: storeReducer.storeSettings?.show_suppliers ?? false,
        show_categories: storeReducer.storeSettings?.show_categories ?? false,
        use_kg_pce: storeReducer.storeSettings?.use_kg_pce ?? false,
        pdf_filter: storeReducer.storeSettings?.pdf_filter ?? PdfFilter.Default,
        categories_orders:
          storeReducer.storeSettings?.categories_orders?.map((category) => ({
            name: category.name,
            children: category.children,
          })) ?? [],
        view_sales_first: storeReducer.storeSettings?.view_sales_first ?? true,
        view_pa: storeReducer.storeSettings?.view_pa ?? true,
        view_local: storeReducer.storeSettings?.view_local ?? false,
        view_pv: storeReducer.storeSettings?.view_pv ?? true,
        edit_pv: storeReducer.storeSettings?.edit_pv ?? false,
        display_purchase_code:
          storeReducer.storeSettings?.display_purchase_code ?? false,
        display_sale_name_ida:
          storeReducer.storeSettings?.display_sale_name_ida ?? false,
        show_checkin_modal:
          storeReducer.storeSettings?.show_checkin_modal ?? false,
        show_breakage_confirm_order:
          storeReducer.storeSettings?.show_breakage_confirm_order ?? true,
        show_low_sales_confirm_order:
          storeReducer.storeSettings?.show_low_sales_confirm_order ?? false,
        limit_order_for_high_breakage:
          storeReducer.storeSettings?.limit_order_for_high_breakage ?? false,
        show_out_of_stock:
          storeReducer.storeSettings?.show_out_of_stock ?? false,
        show_linear_capacity:
          storeReducer.storeSettings?.show_linear_capacity ?? false,
        show_price_modifications:
          storeReducer.storeSettings?.show_price_modifications ?? false,
        show_best_sales_elsewhere:
          storeReducer.storeSettings?.show_best_sales_elsewhere ?? false,
        show_last_pv: storeReducer.storeSettings?.show_last_pv ?? false,
        auto_scroll_inventory:
          storeReducer.storeSettings?.auto_scroll_inventory ?? false,
        show_typology: storeReducer.storeSettings?.show_typology ?? false,
        show_new_references_first:
          storeReducer.storeSettings?.show_new_references_first ?? true,
        show_promotions_first:
          storeReducer.storeSettings?.show_promotions_first ?? true,
      },
    },
  )
  const onSubmit: SubmitHandler<StoreSettingsForm> = async (data) => {
    try {
      const result = await updateStoreSettings({
        variables: {
          input: {
            store_id: storeReducer.storeId ?? "",
            settings: data,
          },
        },
      })

      if (result.data?.updateStoreSettings.error !== null) {
        throw new Error(result.data?.updateStoreSettings.error?.message)
      }

      if (
        (storeReducer.storeSettings?.use_kg_pce ?? false) !== data.use_kg_pce
      ) {
        dispatch({
          type: "setMercurial",
          payload: defaultMercurialReducerState,
        })
      }
      dispatch({
        type: "setUserInterface",
        payload: defaultUserInterfaceReducerState,
      })
      dispatch({
        type: "setStore",
        payload: {
          storeSettings: result.data?.updateStoreSettings.store?.settings,
        },
      })
      navigate(-1)
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Paramètres non sauvegardés")
    }
  }

  const encodedState = useMemo(
    () =>
      encodeURIComponent(
        JSON.stringify({
          storeReducer,
          connectionReducer,
          mercurialReducer,
          trainingModeReducer,
          userInterfaceReducer,
          userReducer,
        }),
      ),
    [
      connectionReducer,
      mercurialReducer,
      storeReducer,
      trainingModeReducer,
      userInterfaceReducer,
      userReducer,
    ],
  )

  const displayPurchaseCode = watch("display_purchase_code")
  const displaySaleNameId = watch("display_sale_name_ida")

  useEffect(() => {
    if (displayPurchaseCode) {
      setValue("display_sale_name_ida", false)
    }
  }, [displayPurchaseCode, setValue])

  useEffect(() => {
    if (displaySaleNameId) {
      setValue("display_purchase_code", false)
    }
  }, [displaySaleNameId, setValue])

  return (
    <>
      <Header />
      <div className="pb-36 md:pb-0 flex px-6 flex-col gap-8">
        <h1 className="font-black text-zinc-800 text-xl">Paramètres</h1>
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">Affichages</h2>
          <form
            onSubmit={(event) => {
              void handleSubmit(onSubmit)(event)
            }}
            className="text-gray-600 flex flex-col gap-4 md:flex-row md:items-end md:justify-between md:gap-0"
          >
            <div className="flex flex-col items-start gap-4 max-w-[370px] w-full">
              <div className="flex items-center justify-between w-full">
                <Label htmlFor="show_suppliers">
                  Afficher les fournisseurs
                </Label>
                <Controller
                  control={control}
                  name="show_suppliers"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Switch
                      ref={ref}
                      id="show_suppliers"
                      checked={value}
                      onCheckedChange={onChange}
                      onBlur={onBlur}
                      className="data-[state=checked]:bg-green-600"
                    />
                  )}
                />
              </div>
              <div className="flex items-center justify-between w-full">
                <Label htmlFor="show_categories">Afficher les catégories</Label>
                <Controller
                  control={control}
                  name="show_categories"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Switch
                      ref={ref}
                      id="show_categories"
                      checked={value}
                      onCheckedChange={onChange}
                      onBlur={onBlur}
                      className="data-[state=checked]:bg-green-600"
                    />
                  )}
                />
              </div>
              <div>
                <Label htmlFor="use_kg_pce">
                  Choix de l&apos;unité de commande
                </Label>
                <Controller
                  control={control}
                  name="use_kg_pce"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={useKGPCEOptions}
                      onChange={onChange}
                      value={value}
                      className="w-full"
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <Label htmlFor="pdf_filter">
                  Ordre des références dans l&apos;envoi de commande
                </Label>
                <Controller
                  control={control}
                  name="pdf_filter"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={pdfFilterOptions}
                      onChange={onChange}
                      value={value}
                      className="w-full"
                    />
                  )}
                />
              </div>
              {isIdaAdmin && (
                <>
                  <hr className="w-full" />
                  <h2 className="font-black text-red-700">Ida Admin</h2>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="view_sales_first">
                      Voir les ventes en premier
                    </Label>
                    <Controller
                      control={control}
                      name="view_sales_first"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="view_sales_first"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="view_pa">
                      Afficher les prix d&#39;achat
                    </Label>
                    <Controller
                      control={control}
                      name="view_pa"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="view_pa"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="view_pv">Afficher les prix de ventes</Label>
                    <Controller
                      control={control}
                      name="view_pv"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="view_pv"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="edit_pv">Modifier les prix de ventes</Label>
                    <Controller
                      control={control}
                      name="edit_pv"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="edit_pv"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_last_pv">
                      Afficher le prix de vente précédent
                    </Label>
                    <Controller
                      control={control}
                      name="show_last_pv"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_last_pv"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="view_local">
                      Afficher le toggle de local
                    </Label>
                    <Controller
                      control={control}
                      name="view_local"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="view_local"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="display_purchase_code">
                      Afficher le code d&apos;achat
                    </Label>
                    <Controller
                      control={control}
                      name="display_purchase_code"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="display_purchase_code"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="display_sale_name_ida">
                      Afficher le code de vente
                    </Label>
                    <Controller
                      control={control}
                      name="display_sale_name_ida"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="display_sale_name_ida"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_checkin_modal">
                      Montrer la modal de checkin
                    </Label>
                    <Controller
                      control={control}
                      name="show_checkin_modal"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_checkin_modal"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_new_references_first">
                      Montrer les nouvelles références en premier dans le tri
                    </Label>
                    <Controller
                      control={control}
                      name="show_new_references_first"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_new_references_first"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_promotions_first">
                      Montrer les promotions en premier dans le tri
                    </Label>
                    <Controller
                      control={control}
                      name="show_promotions_first"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_promotions_first"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_breakage_confirm_order">
                      Confirmation de commande avec démarque
                    </Label>
                    <Controller
                      control={control}
                      name="show_breakage_confirm_order"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_breakage_confirm_order"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_low_sales_confirm_order">
                      Confirmation de commande avec peu de ventes
                    </Label>
                    <Controller
                      control={control}
                      name="show_low_sales_confirm_order"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_low_sales_confirm_order"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="limit_order_for_high_breakage">
                      Bloquer la commande des hautes démarques
                    </Label>
                    <Controller
                      control={control}
                      name="limit_order_for_high_breakage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="limit_order_for_high_breakage"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="auto_scroll_inventory">
                      Passer automatiquement à la référence suivante à la
                      validation
                    </Label>
                    <Controller
                      control={control}
                      name="auto_scroll_inventory"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="auto_scroll_inventory"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <Label htmlFor="show_typology">
                      Afficher les typologies
                    </Label>
                    <Controller
                      control={control}
                      name="show_typology"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Switch
                          ref={ref}
                          id="show_typology"
                          checked={value}
                          onCheckedChange={onChange}
                          onBlur={onBlur}
                          className="data-[state=checked]:bg-green-600"
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <Accordion type="single" collapsible>
                      <AccordionItem value="checkin-settings">
                        <AccordionTrigger>
                          Paramètres de Check-in
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex flex-col space-y-4">
                            {/* <div className="flex items-center justify-between w-full">
                            <Label htmlFor="show_out_of_stock">Afficher les ruptures de stock</Label>
                            <Controller
                              control={control}
                              name="show_out_of_stock"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Switch
                                  ref={ref}
                                  id="show_out_of_stock"
                                  checked={value}
                                  onCheckedChange={onChange}
                                  onBlur={onBlur}
                                  className="data-[state=checked]:bg-green-600"
                                />
                              )}
                            />
                          </div> */}
                            <div className="flex items-center justify-between w-full">
                              <Label htmlFor="show_linear_capacity">
                                Afficher la capacité linéaire
                              </Label>
                              <Controller
                                control={control}
                                name="show_linear_capacity"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Switch
                                    ref={ref}
                                    id="show_linear_capacity"
                                    checked={value}
                                    onCheckedChange={onChange}
                                    onBlur={onBlur}
                                    className="data-[state=checked]:bg-green-600"
                                  />
                                )}
                              />
                            </div>
                            <div className="flex items-center justify-between w-full">
                              <Label htmlFor="show_price_modifications">
                                Afficher modifications des prix d&apos;achat ou
                                de vente (PA/PV)
                              </Label>
                              <Controller
                                control={control}
                                name="show_price_modifications"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Switch
                                    ref={ref}
                                    id="show_price_modifications"
                                    checked={value}
                                    onCheckedChange={onChange}
                                    onBlur={onBlur}
                                    className="data-[state=checked]:bg-green-600"
                                  />
                                )}
                              />
                            </div>
                            {/* <div className="flex items-center justify-between w-full">
                            <Label htmlFor="show_best_sales_elsewhere">
                              Afficher meilleures ventes réalisées ailleurs
                            </Label>
                            <Controller
                              control={control}
                              name="show_best_sales_elsewhere"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Switch
                                  ref={ref}
                                  id="show_best_sales_elsewhere"
                                  checked={value}
                                  onCheckedChange={onChange}
                                  onBlur={onBlur}
                                  className="data-[state=checked]:bg-green-600"
                                />
                              )}
                            />
                          </div> */}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </>
              )}
            </div>
            <Button
              disabled={loading || trainingModeReducer.enable}
              type="submit"
              className="font-black h-14 px-5 bg-green-800 hover:bg-green-900"
            >
              <Save className="size-6" />
              Sauvegarder
            </Button>
          </form>
        </div>
        <hr />
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">Système</h2>
          <div className="flex flex-col gap-2 w-fit">
            <p className="text-zinc-800 text-xs">Mettre à jour le système</p>
            <Button
              asChild
              className="font-black h-14 px-5 bg-cyan-700 hover:bg-cyan-800"
            >
              <a href={`/account/settings?date=${new Date().getTime()}`}>
                <RefreshCcw className="size-6" />
                Mettre à jour
              </a>
            </Button>
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">Mode</h2>
          {isReadOnly && (
            <p className="w-fit flex items-center gap-1 px-2 py-1 rounded bg-yellow-200 text-sm text-yellow-900">
              <TriangleAlert className="size-4" />
              Vous utilisez l&apos;application en mode visualisation, vous ne
              pouvez pas désactiver le{" "}
              <span className="font-bold">mode test</span>
            </p>
          )}
          <div className="w-fit flex items-center gap-2">
            <Label htmlFor="training_mode">Mode test</Label>
            <Switch
              id="training_mode"
              checked={trainingModeReducer.enable}
              onCheckedChange={(checked) => {
                dispatch({ type: "setTrainingMode", payload: checked })
                if (!checked) {
                  dispatch({
                    type: "setMercurial",
                    payload: defaultMercurialReducerState,
                  })
                  dispatch({
                    type: "setUserInterface",
                    payload: defaultUserInterfaceReducerState,
                  })
                }
              }}
              disabled={isReadOnly && !isIdaAdmin}
              className="data-[state=checked]:bg-green-600"
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-6">
          <h2 className="font-black text-zinc-800">
            État de l&apos;application
          </h2>
          <div className="flex flex-col gap-2">
            <p className="text-zinc-800 text-xs">Récupérer le fichier</p>
            <div className="flex gap-4 flex-col md:flex-row">
              <Button
                asChild
                className="font-black h-14 px-5 bg-green-600 hover:bg-green-700"
              >
                <a
                  href={`data:text/plain;charset=utf-8,${encodedState}`}
                  download={`state ida ${new Date().toString()}`}
                >
                  <Download className="size-6" />
                  Télécharger
                </a>
              </Button>
              <Button
                asChild
                variant="outline"
                className="font-black h-14 px-5 border-green-600 text-green-600 hover:text-green-700"
              >
                <a
                  href={`mailto:contact@ai-ida.com?cc=mathieu@ai-ida.com&subject=state&body=${encodedState}`}
                >
                  <Mail className="size-6" />
                  Envoyer par email
                </a>
              </Button>
            </div>
          </div>
        </div>
        <hr />
        <Link
          to="/logout"
          className="flex gap-1.5 items-center text-red-700 text-xs underline mb-20"
        >
          Se déconnecter
        </Link>
      </div>
      <InventoryButtonBottomFooter />
    </>
  )
}

export default SettingsPage
