import { Badge } from "@/components/ui/badge"
import {
  ArrowUp,
  ArrowDown,
  TrendingUp,
  TrendingDown,
  LandPlot,
  Package,
  Truck,
  Edit3,
} from "lucide-react"

interface OrderStatsBarProps {
  countUp: number
  countDown: number
  totalCount: number
  dateTrend: number
  meanModDistance: number
  distancePerDay: number
  totalOrderQuantity: number
  totalReferencesOrdered: number
  meanGain: number
  meanLoss: number
  netResult: number
}

export default function OrderStatsBar(props: OrderStatsBarProps) {
  const {
    countUp,
    countDown,
    totalCount,
    dateTrend,
    meanModDistance,
    distancePerDay,
    totalOrderQuantity,
    totalReferencesOrdered,
    netResult,
  } = props

  const toPercent = (val: number) => {
    if (!totalCount) return 0
    return Math.round((val / totalCount) * 100)
  }

  return (
    <div className="sticky top-0 z-10 bg-white border-b p-2">
      <div className="flex-wrap items-center gap-3 text-xs md:text-sm text-gray-700 flex">
        <Badge className="flex items-center gap-1">
          <Edit3 className="w-3 h-3" /> Stats Modifs:
        </Badge>
        <div className="flex items-center gap-1">
          <ArrowUp className="w-3 h-3 text-red-600" />
          <span>Hausse:</span>
          <strong>{countUp}</strong> / {totalCount} refs{" "}
          <small>({toPercent(countUp)}%)</small>
        </div>
        <div className="flex items-center gap-1">
          <ArrowDown className="w-3 h-3 text-orange-600" />
          <span>Baisse:</span>
          <strong>{countDown}</strong> / {totalCount} refs{" "}
          <small>({toPercent(countDown)}%)</small>
        </div>
        {totalCount > 0 && (
          <div className="flex items-center gap-1 hidden md:flex">
            <span>Tendance:</span>
            {dateTrend >= 0 ? (
              <>
                <TrendingUp className="w-4 h-4 text-red-700" />
                <strong>+{dateTrend}%</strong>
              </>
            ) : (
              <>
                <TrendingDown className="w-4 h-4 text-green-700" />
                <strong>{dateTrend}%</strong>
              </>
            )}
          </div>
        )}
        <div className="flex items-center gap-1 hidden md:flex">
          <LandPlot className="w-3 h-3 text-gray-700" />
          <span>Dist moy:</span>
          <strong>{meanModDistance.toFixed(1)} cs/ref</strong>
        </div>
        <div className="flex items-center gap-1 hidden md:flex">
          <LandPlot className="w-3 h-3 text-gray-700" />
          <span>Dist total /j:</span>
          <strong>{distancePerDay.toFixed(1)} cs</strong>
        </div>
        <div className="flex items-center gap-1 hidden md:flex">
          <Package className="w-3 h-3 text-gray-700" />
          <span>CS commandés /j:</span>
          <strong>{totalOrderQuantity} cs</strong>
        </div>
        <div className="flex items-center gap-1 hidden md:flex">
          <Truck className="w-3 h-3 text-gray-700" />
          <span>Réfs commandées /j:</span>
          <strong>{totalReferencesOrdered}</strong>
        </div>
        <div className="flex items-center gap-1">
          <span>
            {netResult > 0
              ? "Impact total des Modifs. / jour :"
              : netResult < 0
                ? "Impact total des Modifs. / jour :"
                : ""}
          </span>
          {netResult > 0 ? (
            <TrendingUp className="w-4 h-4 text-green-600" />
          ) : netResult < 0 ? (
            <TrendingDown className="w-4 h-4 text-red-600" />
          ) : (
            netResult === 0 && <span className="w-4 h-4" />
          )}
          <strong>
            {netResult > 0
              ? "+" + netResult.toFixed(2) + "€"
              : netResult === 0
                ? ""
                : netResult < 0
                  ? netResult.toFixed(2) + "€"
                  : ""}
          </strong>
        </div>
      </div>
    </div>
  )
}
