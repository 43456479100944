import { StoreSettings } from "@/utils/__generated__/graphql"

export const filterSteps = (
  storeSettings: StoreSettings | null,
  {
    isLinearCapacity,
    isPaChanged,
    isRuptures,
  }: {
    isBreakage: boolean
    isLinearCapacity: boolean
    isPaChanged: boolean
    isRuptures: boolean
  },
) => {
  const allSteps = [
    {
      title: "Vos ruptures",
      key: "potentially_ruptures",
      isDisplayed: isRuptures,
    },
    {
      title: "Vos performances",
      key: "last_performances",
      isDisplayed: true,
    },
    {
      title: " Linéaires à revoir",
      key: "linear_capacity",
      isDisplayed:
        (isLinearCapacity && storeSettings?.show_linear_capacity) ?? false,
    },
    {
      title: "Les bonnes affaires",
      key: "price_modifications",
      isDisplayed:
        (isPaChanged && storeSettings?.show_price_modifications) ?? false,
    },
  ]

  return allSteps.filter((step) => step.isDisplayed)
}
