import { Input } from "@/components/ui/input"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Filter, X, Edit3, ArrowUp, ArrowDown } from "lucide-react"

const FILTER_CONFIG = {
  none: {
    icon: X,
    label: "Aucun filtre",
    colorClasses: "text-gray-700",
  },
  all: {
    icon: Edit3,
    label: "Toutes les modifs",
    colorClasses: "text-blue-600",
  },
  up: {
    icon: ArrowUp,
    label: "Modif à la hausse",
    colorClasses: "text-red-600",
  },
  down: {
    icon: ArrowDown,
    label: "Modif à la baisse",
    colorClasses: "text-orange-600",
  },
}

interface OrderDashFiltersProps {
  referenceFilter: string
  setReferenceFilter: (value: string) => void
  showPromo: boolean
  setShowPromo: (value: boolean) => void
  showTop: boolean
  setShowTop: (value: boolean) => void
  showStock: boolean
  setShowStock: (value: boolean) => void
  showLocal: boolean
  setShowLocal: (value: boolean) => void
  showBreakage: boolean
  setShowBreakage: (value: boolean) => void
  showHighStock: boolean
  setShowHighStock: (value: boolean) => void
  showLowStock: boolean
  setShowLowStock: (value: boolean) => void
  modifFilter: "none" | "all" | "up" | "down"
  setModifFilter: (value: "none" | "all" | "up" | "down") => void
  resetPage: () => void
}

export default function OrderDashFilters(props: OrderDashFiltersProps) {
  const {
    referenceFilter,
    setReferenceFilter,
    showPromo,
    setShowPromo,
    showTop,
    setShowTop,
    showStock,
    setShowStock,
    showLocal,
    setShowLocal,
    showBreakage,
    setShowBreakage,
    showHighStock,
    setShowHighStock,
    showLowStock,
    setShowLowStock,
    modifFilter,
    setModifFilter,
    resetPage,
  } = props

  const getModifFilterButton = () => {
    const config = FILTER_CONFIG[modifFilter]
    const Icon = config.icon
    return (
      <Button
        variant="outline"
        className={`text-xs gap-2 hidden md:flex ${config.colorClasses}`}
      >
        <Icon className="w-4 h-4" /> {config.label}
      </Button>
    )
  }

  return (
    <div className="flex flex-wrap items-center gap-4 mt-4 md:mt-2">
      <Input
        placeholder="(date, magasin, référence...)"
        value={referenceFilter}
        onChange={(e) => {
          setReferenceFilter(e.target.value)
          resetPage()
        }}
        className="w-60"
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="text-xs gap-2">
            <Filter className="w-4 h-4" /> Badges
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48">
          <DropdownMenuLabel className="text-sm text-gray-500">
            Filtrer par badge
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuCheckboxItem
            checked={showPromo}
            onCheckedChange={(checked) => {
              setShowPromo(checked)
              resetPage()
            }}
          >
            Promo
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={showTop}
            onCheckedChange={(checked) => {
              setShowTop(checked)
              resetPage()
            }}
          >
            Top vente en qté
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={showLocal}
            onCheckedChange={(checked) => {
              setShowLocal(checked)
              resetPage()
            }}
          >
            Local
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={showStock}
            onCheckedChange={(checked) => {
              setShowStock(checked)
              resetPage()
            }}
          >
            Stock à écouler
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={showBreakage}
            onCheckedChange={(checked) => {
              setShowBreakage(checked)
              resetPage()
            }}
          >
            Démarque élevée
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={showHighStock}
            onCheckedChange={(checked) => {
              setShowHighStock(checked)
              resetPage()
            }}
          >
            Stock élevé
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={showLowStock}
            onCheckedChange={(checked) => {
              setShowLowStock(checked)
              resetPage()
            }}
          >
            Stock bas
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {getModifFilterButton()}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48">
          <DropdownMenuLabel className="text-sm text-gray-500">
            Filtres de modifications
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              setModifFilter("none")
              resetPage()
            }}
          >
            <span className="flex items-center gap-2">
              <X className="w-3 h-3 text-gray-700" /> Aucun filtre
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setModifFilter("all")
              resetPage()
            }}
          >
            <span className="flex items-center gap-2">
              <Edit3 className="w-3 h-3 text-blue-600" /> Toutes
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setModifFilter("up")
              resetPage()
            }}
          >
            <span className="flex items-center gap-2">
              <ArrowUp className="w-3 h-3 text-red-600" /> hausse
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setModifFilter("down")
              resetPage()
            }}
          >
            <span className="flex items-center gap-2">
              <ArrowDown className="w-3 h-3 text-orange-600" /> baisse
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
