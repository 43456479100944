import {
  type AllMercurialInfo,
  type UpdatedReference,
} from "../reducers/mercurialReducer"
import { type BatchUpdateData } from "./__generated__/graphql"

export function getBatchData(
  mercurialeInfos: Partial<AllMercurialInfo>[],
  updatedReferences: Record<string, UpdatedReference>,
): BatchUpdateData[] {
  const reducedMercurialeInfos = mercurialeInfos.reduce<
    Record<string, Partial<AllMercurialInfo>>
  >((reducedMercurialeInfos, mercurialeInfo) => {
    if (typeof mercurialeInfo.mercuriale_id !== "string")
      return reducedMercurialeInfos
    reducedMercurialeInfos[mercurialeInfo.mercuriale_id] = mercurialeInfo
    return reducedMercurialeInfos
  }, {})
  const batchData: BatchUpdateData[] =
    Object.values(updatedReferences)
      .filter(
        (updatedReference) =>
          reducedMercurialeInfos[updatedReference.mercurialeId] !== undefined,
      )
      .map((updatedReference) => {
        const mercuriale = reducedMercurialeInfos[updatedReference.mercurialeId]
        return {
          order_id: mercuriale?.order_id,
          mercuriale_id: mercuriale?.mercuriale_id ?? "",
          back_inventory:
            updatedReference?.backInventoryQuantity ??
            mercuriale?.back_inventory_qty,
          floor_inventory:
            updatedReference?.floorInventoryQuantity ??
            mercuriale?.floor_inventory_qty,
          quantity_actual: updatedReference?.orderInventoryQuantity ?? null,
          shelf_floor_size:
            updatedReference?.shelfFloorSize ?? mercuriale?.shelf_floor_size,
          colisage: mercuriale?.colisage,
          dim_mercuriale_id:
            "dim_mercuriale_id" in mercuriale
              ? mercuriale?.dim_mercuriale_id
              : null,
          sale_id: "sale_id" in mercuriale ? mercuriale?.sale_id : null,
          stock_to_verify_flag:
            updatedReference?.stock_to_verify_flag ??
            mercuriale?.stock_to_verify_flag,
          time_rupture_flag_verified:
            updatedReference?.time_rupture_flag_verified ??
            mercuriale?.time_rupture_flag_verified,
        }
      }) ?? []

  return batchData
}
