"use client"

import { useState } from "react"
import { matchPath, Outlet, useParams, useSearchParams } from "react-router-dom"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  CheckCircle,
  PackageSearch,
  PanelRightClose,
  PanelRightOpen,
  LucideTarget,
} from "lucide-react"
import { twJoin } from "tailwind-merge"
import { Link } from "react-router-dom"

const sidebarNavItems = [
  {
    id: "matching",
    title: "Suivi du non liée",
    icon: PackageSearch,
  },
  {
    id: "orders-regrouping",
    title: "Achat",
    icon: LucideTarget,
  },
  {
    id: "sales-matching",
    title: "Vente",
    icon: CheckCircle,
  },
] as const

export function RegroupingIdaPage() {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  return (
    <div className="flex w-full h-full">
      <div
        className={twJoin(
          "border-r p-4 transition-all flex flex-col",
          "text-gray-800",

          isSidebarOpen ? "min-w-64 w-64" : "min-w-20 w-20",
        )}
      >
        <div className="flex items-center mb-4 border-b">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsSidebarOpen((prev) => !prev)}
            className="mr-2"
          >
            {isSidebarOpen ? (
              <PanelRightOpen className="h-5 w-5" />
            ) : (
              <PanelRightClose className="h-5 w-5" />
            )}
          </Button>
          {isSidebarOpen && (
            <h2 className="text-lg font-semibold truncate ">Regroupement</h2>
          )}
        </div>

        {/* Navigation Items */}
        <div className="flex-1 flex flex-col space-y-1">
          {sidebarNavItems
            .filter(
              (item) =>
                item.id !== "orders-regrouping" ||
                companyId === "2323cc9b-ea58-38fc-5eb2-36d27aef094d",
            )
            .map((item) => {
              const Icon = item.icon
              const active =
                matchPath(
                  `/admin/companies/:companyId/regrouping-ida/${item.id}`,
                  location.pathname,
                ) !== null

              return (
                <Button
                  key={item.id}
                  variant={active ? "default" : "ghost"}
                  className={cn(
                    "w-full justify-start gap-2 rounded-md p-2 hover:bg-gray-300",
                    active && "bg-primary text-white hover:bg-primary/90",
                  )}
                  asChild
                >
                  <Link
                    to={`${item.id}${
                      franchiseParam !== undefined
                        ? `?franchise=${franchiseParam}`
                        : ""
                    }`}
                  >
                    <Icon className="size-5" />
                    {isSidebarOpen && item.title}
                  </Link>
                </Button>
              )
            })}
        </div>
      </div>
      <div className="flex-1 w-full h-full overflow-x-hidden flex flex-col">
        <Outlet />
      </div>
    </div>
  )
}
