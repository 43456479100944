import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, type StateType } from "../types"
import {
  Category,
  useCategoriesQuery,
  useUpdateStoreSettingsMutation,
} from "../utils/__generated__/graphql"
import { useEffect, useMemo, useState } from "react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import {
  removeDuplicates,
  removeDuplicatesValues,
} from "../utils/removeDuplicates"
import { Spinner } from "../ui/Spinner"
import { toast } from "sonner"
import Header from "@/components/header"
import { InventoryButtonBottomFooter } from "@/components/inventoryButtonBottomFooter"
import { Button } from "@/components/ui/button"
import { Save } from "lucide-react"

export function CategoriesPage(): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()

  const { storeId, storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const [updateStoreSettings, { loading: updateLoading }] =
    useUpdateStoreSettingsMutation()
  const { data, loading: categoriesLoading } = useCategoriesQuery({
    variables: { input: { store_id: storeId! } },
  })

  const [selectedElement, setSelectedElement] = useState<
    { type: "category" | "subCategory"; index: number } | undefined
  >()

  const [newCategoriesOrder, setNewCategoriesOrder] = useState<
    Category[] | undefined
  >(undefined)
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)
  const [openedCategory, setOpenedCategory] = useState<Category | undefined>()

  const categories = useMemo(() => {
    if (newCategoriesOrder !== undefined) return newCategoriesOrder

    const retrievedCategories =
      data?.getCategories.categories?.map((category) => ({
        name: category.name,
        children: category.children,
      })) ?? []
    const orderedCategories =
      storeSettings?.categories_orders?.reduce<Category[]>((acc, category) => {
        const retrievedCategory = retrievedCategories.find(
          (retrievedCategory) => retrievedCategory.name === category.name,
        )
        const children = removeDuplicates([
          ...category.children,
          ...(retrievedCategory?.children ?? []),
        ])
        return [...acc, { name: category.name, children }]
      }, []) ?? []
    return removeDuplicatesValues(
      [...orderedCategories, ...retrievedCategories],
      "name",
    )
  }, [
    data?.getCategories.categories,
    newCategoriesOrder,
    storeSettings?.categories_orders,
  ])

  useEffect(() => {
    if (data?.getCategories.categories?.length !== 1) return

    setSelectedElement(undefined)
    setOpenedCategory(data.getCategories.categories[0])
  }, [data?.getCategories.categories])

  async function saveCategoriesOrder() {
    if (newCategoriesOrder === undefined || isTestMode) return

    try {
      const result = await updateStoreSettings({
        variables: {
          input: {
            store_id: storeId ?? "",
            settings: {
              categories_orders: newCategoriesOrder,
            },
          },
        },
      })

      if (result.data?.updateStoreSettings.error !== null) {
        throw new Error(result.data?.updateStoreSettings.error?.message)
      }

      dispatch({
        type: "setStore",
        payload: {
          storeSettings: result.data?.updateStoreSettings.store?.settings,
        },
      })
      setNewCategoriesOrder(undefined)
      setSelectedElement(undefined)
      setInputValue(undefined)
    } catch (error) {
      console.error(error)
      toast.error("Ordre non sauvegardé")
    }
  }

  function moveElementTo(index: number | undefined) {
    if (
      selectedElement === undefined ||
      selectedElement.index < 0 ||
      index === undefined ||
      index < 0
    )
      return
    if (selectedElement.type === "category" && index > categories.length - 1)
      return
    if (
      (selectedElement.type === "subCategory" &&
        openedCategory === undefined) ||
      (selectedElement.type === "subCategory" &&
        index > (openedCategory?.children ?? []).length - 1)
    )
      return

    if (selectedElement.type === "category") {
      const newArray = [...categories]
      const deletedCategories = newArray.splice(selectedElement.index, 1)
      newArray.splice(index, 0, ...deletedCategories)
      setNewCategoriesOrder(newArray)
      setSelectedElement((_selectedElement) => ({
        type: _selectedElement!.type,
        index,
      }))
      setInputValue(`${index + 1}`)
    }

    if (selectedElement.type === "subCategory") {
      const children =
        categories.find((category) => openedCategory!.name === category.name)
          ?.children ?? []
      const newArray = [...children]
      const deletedCategories = newArray.splice(selectedElement.index, 1)
      newArray.splice(index, 0, ...deletedCategories)
      const categoryIndex = categories.findIndex(
        (category) => openedCategory!.name === category.name,
      )
      const newCategories = [...categories]
      newCategories[categoryIndex].children = newArray
      setNewCategoriesOrder(newCategories)
      setSelectedElement((_selectedElement) => ({
        type: _selectedElement!.type,
        index,
      }))
      setInputValue(`${index + 1}`)
    }
  }

  if (categoriesLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="px-2 pb-28 md:pb-6 md:px-4 flex flex-col gap-6 text-gray-600 flex-1 h-full overflow-y-hidden">
        <div className="sticky top-0 rounded-t flex justify-between items-center">
          <div className="text-zinc-800">
            <p className="font-black text-2xl">Tous les produits</p>
            <p className="text-sm">
              Choisissez l&apos;ordre de vos produits en fonction de vos rayons.
            </p>
          </div>
          <div className="flex gap-1 h-fit">
            <Button
              type="button"
              variant="outline"
              className="w-12 h-12"
              disabled={
                selectedElement === undefined ||
                (selectedElement.type === "category" &&
                  selectedElement.index >= categories.length - 1) ||
                (selectedElement.type === "subCategory" &&
                  selectedElement.index >=
                    (openedCategory?.children ?? []).length - 1)
              }
              onClick={() => {
                if (selectedElement === undefined) return
                moveElementTo(selectedElement.index + 1)
              }}
            >
              <ChevronDownIcon className="size-6" />
            </Button>
            <Button
              type="button"
              variant="outline"
              className="w-12 h-12"
              disabled={
                selectedElement === undefined || selectedElement.index <= 0
              }
              onClick={() => {
                if (selectedElement === undefined) return
                moveElementTo(selectedElement.index - 1)
              }}
            >
              <ChevronUpIcon className="size-6" />
            </Button>
            <Button
              type="button"
              disabled={
                updateLoading || newCategoriesOrder === undefined || isTestMode
              }
              className="w-fit h-12 justify-end"
              onClick={saveCategoriesOrder}
            >
              <Save className="size-6" />
              Sauvegarder l&apos;ordre
            </Button>
          </div>
        </div>
        <hr className="border-t-2" />
        <div className="flex-1 overflow-y-auto">
          {categories.map((category, categoryIndex) => (
            <div key={categoryIndex}>
              <div
                className={`${data?.getCategories.categories?.length === 1 ? "hidden" : "flex"} sticky top-0 w-full rounded p-4 ${selectedElement?.type === "category" && categoryIndex === selectedElement?.index ? "bg-red-700 text-white" : "text-zinc-800 bg-zinc-50"}`}
              >
                <button
                  type="button"
                  className="flex items-center uppercase flex-1 gap-8"
                  onClick={() => {
                    setSelectedElement({
                      type: "category",
                      index: categoryIndex,
                    })
                    setInputValue(`${categoryIndex + 1}`)
                    setOpenedCategory(undefined)
                  }}
                >
                  {selectedElement?.type === "category" &&
                  categoryIndex === selectedElement.index ? (
                    <input
                      type="number"
                      min={1}
                      max={categories.length}
                      className={`font-medium w-14 bg-white border-none rounded focus:ring-green-500 text-zinc-800 ${selectedElement?.type === "category" && categoryIndex === selectedElement.index ? "underline" : ""}`}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key !== "Enter" || inputValue === undefined)
                          return
                        const value = parseInt(inputValue)
                        if (isNaN(value)) return
                        moveElementTo(value - 1)
                      }}
                    />
                  ) : (
                    <p
                      className={`font-medium ${selectedElement?.type === "category" && categoryIndex === selectedElement.index ? "underline" : ""}`}
                    >
                      {categoryIndex + 1}
                    </p>
                  )}
                  <p className="text-lg font-black">{category.name}</p>
                </button>
                <button
                  onClick={() => {
                    setSelectedElement(undefined)
                    setOpenedCategory((_openedCategory) => {
                      if (_openedCategory?.name === category.name) {
                        return undefined
                      }
                      return category
                    })
                  }}
                >
                  {openedCategory?.name === category.name ? (
                    <ChevronUpIcon className="w-6 h-6" />
                  ) : (
                    <ChevronDownIcon className="w-6 h-6" />
                  )}
                </button>
              </div>
              {openedCategory?.name === category.name && (
                <div className="py-3 px-3 bg-zinc-100">
                  {category.children.map((subCategory, subCategoryIndex) => (
                    <button
                      key={subCategory}
                      type="button"
                      className={`flex justify-between items-center uppercase w-full rounded p-2 ${selectedElement?.type === "subCategory" && subCategoryIndex === selectedElement.index ? "bg-cyan-700 text-white" : "text-zinc-800"}`}
                      onClick={() => {
                        setSelectedElement({
                          type: "subCategory",
                          index: subCategoryIndex,
                        })
                        setInputValue(`${subCategoryIndex + 1}`)
                      }}
                    >
                      <span className="flex items-center gap-4 w-full">
                        {selectedElement?.type === "subCategory" &&
                        subCategoryIndex === selectedElement.index ? (
                          <input
                            type="number"
                            min={1}
                            max={category.children.length}
                            className={`font-medium w-8 p-1 text-sm bg-white border-none rounded focus:ring-green-500 text-zinc-800 ${selectedElement?.type === "subCategory" && subCategoryIndex === selectedElement.index ? "underline" : ""}`}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key !== "Enter" || inputValue === undefined)
                                return
                              const value = parseInt(inputValue)
                              if (isNaN(value)) return
                              moveElementTo(value - 1)
                            }}
                          />
                        ) : (
                          <p
                            className={`font-medium ${selectedElement?.type === "subCategory" && subCategoryIndex === selectedElement.index ? "underline" : ""}`}
                          >
                            {subCategoryIndex + 1}
                          </p>
                        )}
                        <p className="font-black">{subCategory}</p>
                      </span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <InventoryButtonBottomFooter />
    </div>
  )
}
