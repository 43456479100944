import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  InProgressDeliveriesItem,
  useBatchUpdateMutation,
  useGetInProgressDeliveriesQuery,
  useLogsModificationUpdateMutation,
} from "../../utils/__generated__/graphql"
import { type DispatchActionType, type StateType } from "../../types"
import { type AllMercurialInfo } from "../../reducers/mercurialReducer"
import { InventoryPageTemplate } from "../../components/inventory/InventoryPageTemplate"
import { handleKeyboard } from "../../utils/handleKeyboard"
import { Dialog, Transition } from "@headlessui/react"
import { AlertTriangleIcon } from "../../assets/AlertTriangle"
import { Button } from "../../ui/Button"
import { SortOption } from "../../reducers/userInterfaceReducer"
import { InventoryRow } from "../../components/inventory/InventoryRow"
import {
  bestSellersSelector,
  filteredMercurialeReducerSelector,
  selectedRankingsSelector,
} from "../../selectors/mercurialeSelectors"
import { removeDuplicatesValues } from "../../utils/removeDuplicates"
import { useOutletContext } from "react-router-dom"
import { InventoryContext } from "../../components/inventory/InventoryRoot"
import { useSearchBar } from "../../hooks/useSearchBar"
import {
  filterInventory,
  sortInventory,
  useScrollSectionOptions,
} from "@/utils/filterAndSortInventory"
import { ScrollToSectionDropdown } from "@/components/inventory/nav/ScrollToSectionDropdown"
import { VirtuosoHandle } from "react-virtuoso"
import { RuptureLayout } from "./RuptureLayout"
import { hasBadges } from "@/utils/hasBadges"

// Sort options that should not keep the same order when updating data
const bypassOldSortOptions = [SortOption.SortIndex]

function InventoryPage(): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const [{ isLoading }, getLatestInfos] = useOutletContext<InventoryContext>()

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const {
    storeId,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
    companyName,
    storeFranchise,
  } = useSelector((state: StateType) => state.storeReducer)
  const {
    mercurialAndStoreInventories,
    updatedReferences,
    selectedDimMercurialeId,
  } = useSelector(filteredMercurialeReducerSelector)
  const bestSellers = useSelector(bestSellersSelector)
  const rankings = useSelector(selectedRankingsSelector)
  const {
    searchTerm,
    familyName,
    subFamilyName,
    suppliersIds,
    filteredReferences,
    sortOption,
    displayShelfFloorSize,
  } = useSelector(
    (state: StateType) => state.userInterfaceReducer.inventoryPage,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  // Keep sort list and option to keep order when updating data
  const oldState = useRef<{
    sortList?: string[]
    sortOption?: SortOption
    selectedDimMercurialeId?: string
  } | null>(null)

  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  const [isReloadModalOpen, setIsReloadModal] = useState(false)
  const [isMercurialeModalOpen, setIsMercurialeModalOpen] = useState(false)

  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  const interval = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isReloadModalOpen) return

    interval.current = setInterval(() => {
      setIsReloadModal(true)
      clearInterval(interval.current)
    }, 36000000) // 10 hours

    return () => {
      clearInterval(interval.current)
    }
  }, [isReloadModalOpen])

  const mercurialeInfos = useMemo<Partial<AllMercurialInfo>[]>(() => {
    return mercurialAndStoreInventories.map((mercurialeInfo) => {
      const updatedReference =
        updatedReferences[mercurialeInfo.mercuriale_id ?? ""]
      return {
        ...mercurialeInfo,
        back_inventory_qty:
          updatedReference?.backInventoryQuantity ??
          mercurialeInfo.back_inventory_qty,
        floor_inventory_qty:
          updatedReference?.floorInventoryQuantity ??
          mercurialeInfo.floor_inventory_qty,
        quantity_actual:
          updatedReference?.orderInventoryQuantity ??
          mercurialeInfo.quantity_actual,
        shelf_floor_size:
          updatedReference?.shelfFloorSize ?? mercurialeInfo.shelf_floor_size,
        shelf_floor_size_updated_at:
          updatedReference?.shelfFloorSizeUpdatedAt ??
          mercurialeInfo.shelf_floor_size_updated_at,
        stock_too_high_flag:
          updatedReference?.stock_too_high_flag ??
          mercurialeInfo.stock_too_high_flag,
        stock_too_low_flag:
          updatedReference?.stock_too_low_flag ??
          mercurialeInfo.stock_too_low_flag,
        time_rupture_flag_verified:
          updatedReference?.time_rupture_flag_verified ??
          mercurialeInfo.time_rupture_flag_verified,
        time_rupture_flag: mercurialeInfo.time_rupture_flag,
        stock_to_verify_flag:
          updatedReference?.stock_to_verify_flag ??
          mercurialeInfo.stock_to_verify_flag,
        local_flag: updatedReference?.local_flag ?? mercurialeInfo.local_flag,
        local_delivery_days:
          updatedReference?.local_delivery_days ??
          mercurialeInfo.local_delivery_days,
      }
    })
  }, [mercurialAndStoreInventories, updatedReferences])

  const greyedReferences = useMemo(() => {
    return mercurialeInfos.filter(
      (item) =>
        item.has_historical === false && hasBadges(item).hasBadges === false,
    )
  }, [mercurialeInfos])

  // Sort logic
  const sortedMercurialeInfos = useMemo<Partial<AllMercurialInfo>[]>(() => {
    const _mercurialesInfos = [...mercurialeInfos]

    const withHistorical = _mercurialesInfos.filter(
      (item) =>
        greyedReferences.some(
          (reference) => reference.mercuriale_id === item.mercuriale_id,
        ) === false,
    )

    if (
      !bypassOldSortOptions.includes(sortOption) &&
      oldState.current?.sortOption === sortOption &&
      oldState.current.sortList !== undefined &&
      oldState.current.selectedDimMercurialeId === selectedDimMercurialeId
    ) {
      const sortedItems = oldState.current.sortList
        .map((mercurialeId) => {
          const mercurialeInfo = _mercurialesInfos.find(
            (_filteredMercurialeInfo) =>
              _filteredMercurialeInfo.mercuriale_id === mercurialeId,
          )
          return mercurialeInfo
        })
        .filter(
          (mercurialeInfo): mercurialeInfo is Partial<AllMercurialInfo> =>
            mercurialeInfo !== undefined,
        )

      return [
        ...sortedItems.filter(
          (item) =>
            greyedReferences.some(
              (reference) => reference.mercuriale_id === item.mercuriale_id,
            ) === false,
        ),
        ...sortedItems.filter((item) =>
          greyedReferences.some(
            (reference) => reference.mercuriale_id === item.mercuriale_id,
          ),
        ),
      ]
    }

    const _sortedWithHistorical = sortInventory(
      withHistorical,
      sortOption,
      storeSettings,
      rankings,
    )

    const _sortedWithoutHistorical = sortInventory(
      greyedReferences,
      sortOption,
      storeSettings,
      rankings,
    )

    const _sortedMercurialeInfos = [
      ..._sortedWithHistorical,
      ..._sortedWithoutHistorical,
    ]

    oldState.current = {
      sortOption,
      sortList: _sortedMercurialeInfos.map(
        (_sortedMercurialeInfo) => _sortedMercurialeInfo.mercuriale_id ?? "",
      ),
      selectedDimMercurialeId: selectedDimMercurialeId,
    }

    return _sortedMercurialeInfos
  }, [
    mercurialeInfos,
    sortOption,
    selectedDimMercurialeId,
    storeSettings,
    rankings,
    greyedReferences,
  ])

  const searchedMercurialeInfos = useSearchBar(
    sortedMercurialeInfos,
    searchTerm,
  )

  // Other filters logic (separated from search filter to retrieve number of filtered items)
  const filteredMercurialeInfos = useMemo<Partial<AllMercurialInfo>[]>(() => {
    return filterInventory(
      searchedMercurialeInfos,
      familyName,
      subFamilyName,
      suppliersIds,
      filteredReferences,
      storeSettings?.typologies,
    )
  }, [
    familyName,
    filteredReferences,
    searchedMercurialeInfos,
    storeSettings?.typologies,
    subFamilyName,
    suppliersIds,
  ])

  // Other filters logic (separated from search filter to retrieve number of filtered items)
  const deduplicateFilteredMercurialeInfos = useMemo<
    Partial<AllMercurialInfo>[]
  >(() => {
    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
  }, [filteredMercurialeInfos])

  const unfilteredAmount = mercurialeInfos.length
  const searchedAmount = searchedMercurialeInfos.length
  const filteredAmount = filteredMercurialeInfos.length

  const updateInventory = useCallback(
    async (
      value: string,
      _selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
    ): Promise<void> => {
      await handleKeyboard({
        value,
        selectedInventory: _selectedInventory ?? selectedInventory,
        mercurialeInfos: mercurialAndStoreInventories,
        updatedReferences,
        storeSettings,
        dispatch,
        batchUpdateMutation,
        online,
        isTestMode,
        storeId,
        dimOrderRequestId,
        logsModificationUpdate,
      })
    },
    [
      batchUpdateMutation,
      dimOrderRequestId,
      dispatch,
      isTestMode,
      mercurialAndStoreInventories,
      online,
      selectedInventory,
      storeId,
      storeSettings,
      updatedReferences,
      logsModificationUpdate,
    ],
  )

  const scrollSections = [
    ...useScrollSectionOptions({
      unfilteredInventory: sortedMercurialeInfos.filter(
        (item) =>
          greyedReferences.some(
            (reference) => reference.mercuriale_id === item.mercuriale_id,
          ) === false,
      ),
      inventory: deduplicateFilteredMercurialeInfos.filter(
        (item) =>
          greyedReferences.some(
            (reference) => reference.mercuriale_id === item.mercuriale_id,
          ) === false,
      ),
      sortOption,
      categoriesOrder: storeSettings?.categories_orders,
      showNewReferencesFirst: storeSettings?.show_new_references_first,
      showPromotionsFirst: storeSettings?.show_promotions_first,
      bestSellers,
    }),
    ...(deduplicateFilteredMercurialeInfos.some((item) =>
      greyedReferences.some(
        (reference) => reference.mercuriale_id === item.mercuriale_id,
      ),
    )
      ? [
          {
            label: "Sans historique",
            index: deduplicateFilteredMercurialeInfos.findIndex((item) =>
              greyedReferences.some(
                (reference) => reference.mercuriale_id === item.mercuriale_id,
              ),
            ),
          },
        ]
      : []),
  ]

  const virtuosoRef = useRef<VirtuosoHandle>(null)

  const { data: inProgressDeliveriesData } = useGetInProgressDeliveriesQuery({
    variables: {
      input: {
        store_id: storeId!,
        sale_ids: mercurialAndStoreInventories
          .map((mercurialeInfo) => mercurialeInfo.sale_id)
          .filter((saleId): saleId is string => saleId !== undefined),
        dim_order_request_id: dimOrderRequestId ?? null,
      },
    },
  })

  const ruptureToVerify = mercurialAndStoreInventories
    .filter(
      (mercurialeInfo) =>
        mercurialeInfo.dim_order_request_status === "ongoing" &&
        Array.isArray(mercurialeInfo.quantity_predicted_array) &&
        mercurialeInfo.quantity_predicted_array.length > 0,
    )
    .sort((a, b) => (b.rupture_loss_amount ?? 0) - (a.rupture_loss_amount ?? 0))
    .slice(0, 5)

  return (
    <>
      <Transition appear show={isReloadModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsReloadModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col gap-4">
                  <div className="flex justify-center items-center">
                    <AlertTriangleIcon />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    Voulez-vous récupérer les dernières données de la{" "}
                    {companyName === "auchan" ? "commande" : "mercuriale"} ?
                  </Dialog.Title>
                  <p className="text-center text-gray-500 text-sm">
                    La {companyName === "auchan" ? "commande" : "mercuriale"}{" "}
                    n&apos;a pas été mise à jour depuis 1 heure.
                  </p>
                  <div className="w-full flex items-center gap-3 justify-center">
                    <Button
                      onClick={() => setIsReloadModal(false)}
                      className="w-full h-[44px] text-[16px]"
                      color="ghost"
                    >
                      Annuler
                    </Button>
                    <Button
                      color="green"
                      onClick={() => {
                        oldState.current = null
                        void getLatestInfos()
                        setIsReloadModal(false)
                      }}
                      className="w-full h-[44px] text-[16px]"
                    >
                      Mettre à jour
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <InventoryPageTemplate
        page="inventoryPage"
        deduplicateFilteredMercurialeInfos={deduplicateFilteredMercurialeInfos}
        isMercurialeModalOpen={isMercurialeModalOpen}
        setIsMercurialeModalOpen={setIsMercurialeModalOpen}
        oldState={oldState}
        currentIndex={currentIndex}
        loading={isLoading}
        rawData={mercurialeInfos}
        virtuosoRef={virtuosoRef}
        scrollSectionRenderer={(index) => (
          <ScrollToSectionDropdown
            activeIndex={index}
            options={scrollSections}
            handleScrollTo={(index) =>
              virtuosoRef.current?.scrollToIndex({
                index,
                offset: 0,
                align: "start",
              })
            }
          />
        )}
        dataLength={deduplicateFilteredMercurialeInfos.length}
        searchedAmount={searchedAmount}
        filteredAmount={filteredAmount}
        filteredMercurialeInfos={filteredMercurialeInfos}
        unfilteredAmount={unfilteredAmount}
        updateInventory={updateInventory}
        rowContent={(index) => {
          const row = deduplicateFilteredMercurialeInfos[index]

          const relatedInProgressDeliveries =
            inProgressDeliveriesData?.getInProgressDeliveries.data
              .filter(
                (delivery: InProgressDeliveriesItem) =>
                  delivery.sale_id === row.sale_id,
              )
              .reduce(
                (
                  uniqueDeliveries: InProgressDeliveriesItem[],
                  currentDelivery,
                ) => {
                  const isDuplicate = uniqueDeliveries.some(
                    (item) => item.order_id === currentDelivery.order_id,
                  )

                  if (!isDuplicate) {
                    uniqueDeliveries.push(currentDelivery)
                  }

                  return uniqueDeliveries
                },
                [],
              )

          const references = filteredMercurialeInfos.filter(
            (mercurialeInfo) => mercurialeInfo.sale_id === row.sale_id,
          )

          const isRowGreyed = greyedReferences.some(
            (reference) => reference.mercuriale_id === row.mercuriale_id,
          )
          row.lineaire_updated_to_zero_flag

          const itemIsNew = references.some(
            (reference) => reference.new_reference === true,
          )

          const hasBigBreakage = references.some(
            (reference) =>
              typeof reference.breakage_percentage === "number" &&
              reference.breakage_percentage > 10,
          )

          const isRuptureToVerify = ruptureToVerify.some(
            (verify) => verify.mercuriale_id === references[0].mercuriale_id,
          )

          return (
            <div key={references[0]?.mercuriale_id ?? ""}>
              {references[0]?.time_rupture_flag && isRuptureToVerify && (
                <RuptureLayout
                  references={references}
                  updateInventory={updateInventory}
                  isWarning={itemIsNew}
                  isDanger={hasBigBreakage}
                />
              )}
              <InventoryRow
                index={index}
                storeId={storeId}
                setCurrentIndex={setCurrentIndex}
                searchTerm={searchTerm}
                bestSellers={bestSellers}
                selectedInventory={selectedInventory}
                isOnline={online}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                displayShelfFloorSize={displayShelfFloorSize}
                storeSuppliers={storeSuppliers}
                storeCurrency={storeCurrency}
                companyId={companyId}
                references={references}
                companyName={companyName}
                franchise={storeFranchise}
                inProgressDeliveries={relatedInProgressDeliveries}
                isRowGreyed={isRowGreyed}
                isRuptureToVerify={isRuptureToVerify}
                className={
                  references[0].time_rupture_flag
                    ? "rounded-t-none"
                    : "rounded-t-xl"
                }
              />
            </div>
          )
        }}
      />
    </>
  )
}

export default InventoryPage
