import { useState } from "react"
import Header from "@/components/header"
import { EvolutionOfTheWeek } from "./sections/evolutionOfTheWeek"
import { LastWeekPerformancesHome } from "./sections/LastWeekPerformancesHome"

import { OrderHistorical } from "./sections/orderHistorical"
import { ArrowRight, ChartColumn, History } from "lucide-react"
import { usePermissions } from "@/hooks/usePermissions"
import { Button } from "@/ui/Button"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "@/contexts/AppContext"
import { UnMatchedReferencesWidget } from "./sections/UnMatchedReferencesWidget"

export const HomePage = () => {
  const [activeTab, setActiveTab] = useState<string>("performances")

  const tabs = [
    {
      id: "performances",
      label: "Évolution",
      icon: <ChartColumn />,
      component: (
        <div className="flex flex-col gap-2 mb-20">
          <LastWeekPerformancesHome />
          <EvolutionOfTheWeek />
        </div>
      ),
    },
    {
      id: "historical",
      label: "Historique",
      icon: <History />,
      component: <OrderHistorical />,
    },
  ]

  const { headerHeight } = useAppContext()

  const { isIdaAdmin } = usePermissions()
  const navigate = useNavigate()
  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="flex-1 block md:hidden">
          <div className="h-full mb-44">
            {tabs.find((tab) => tab.id === activeTab)?.component}
          </div>
        </div>
        <div className="hidden md:grid grid-cols-1 md:grid-cols-2 gap-4 px-4 mb-20 md:mb-0">
          <div
            className="col-span-1"
            style={{
              maxHeight: `calc(100vh - ${headerHeight + 20}px)`,
            }}
          >
            <OrderHistorical />
          </div>
          <div
            className="col-span-1 grid grid-cols-1 gap-4"
            style={{
              maxHeight: `calc(100vh - ${headerHeight + 20}px)`,
            }}
          >
            <UnMatchedReferencesWidget />
            <LastWeekPerformancesHome />
            <EvolutionOfTheWeek />
          </div>
        </div>

        <div className="fixed bottom-0 left-0 w-full bg-white border-t shadow-lg md:hidden">
          <div
            className={`flex flex-col justify-around py-2 ${isIdaAdmin ? "mb-10" : ""} gap-2`}
          >
            <Button
              onClick={() => {
                navigate("/inventory")
              }}
              className="bg-green-800 text-white  hover:bg-green-900 hover:text-white py-6 mx-4"
            >
              <p>Passer commande</p>
              <ArrowRight className="w-4 h-4" />
            </Button>
            <div className="flex flex-row justify-around">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex flex-col items-center text-xs px-4 py-1 rounded-md text-black ${
                    activeTab === tab.id ? "bg-[#F1F5F9]" : ""
                  }`}
                >
                  {tab.icon}
                  <span className="mt-1 text-black">{tab.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
