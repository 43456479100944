import { useEffect, useState } from "react"
import { TooLowProducts } from "./TooLowProducts"
import { ExcessiveProducts } from "./ExcessiveProducts"
import { BoxProducts } from "./BoxProducts"
import { Button } from "@/components/ui/button"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { DialogFooter, DialogTitle } from "@/components/ui/dialog"
import { NotAvailableProducts } from "./NotAvailableProducts"

interface CheckoutModalProps {
  tooLowProducts: AllMercurialInfo[]
  excessiveProducts: AllMercurialInfo[]
  boxProducts: AllMercurialInfo[]
  notAvailableProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
  handleClose: () => void
  handleConfirm: () => void
  companyName: string | null
  franchise: string | null
}

export const CheckoutModalContent = ({
  tooLowProducts,
  excessiveProducts,
  notAvailableProducts,
  boxProducts,
  allItems,
  handleClose,
  handleConfirm,
  companyName,
  franchise,
}: CheckoutModalProps) => {
  const [tooLowProductIds, setTooLowProductIds] = useState<string[]>()
  const [excessiveProductIds, setExcessiveProductIds] = useState<string[]>()
  const [boxProductIds, setBoxProductIds] = useState<string[]>()

  useEffect(() => {
    if (tooLowProductIds === undefined) {
      setTooLowProductIds(tooLowProducts.map((item) => item.mercuriale_id!))
    }
  }, [tooLowProductIds, tooLowProducts])

  useEffect(() => {
    if (excessiveProductIds === undefined) {
      setExcessiveProductIds(
        excessiveProducts.map((item) => item.mercuriale_id!),
      )
    }
  }, [excessiveProductIds, excessiveProducts])

  useEffect(() => {
    if (boxProductIds === undefined) {
      setBoxProductIds(boxProducts.map((item) => item.mercuriale_id!))
    }
  }, [boxProductIds, boxProducts])

  return (
    <div className="flex-1 overflow-y-auto">
      <DialogTitle className="sticky top-0 left-0 right-0 bg-white pb-2 z-10">
        <h1 className="text-black text-2xl font-bold">🚨 Avertissement</h1>
      </DialogTitle>
      <div className="flex-col flex gap-6">
        {notAvailableProducts.length > 0 && (
          <NotAvailableProducts notAvailableProducts={notAvailableProducts} />
        )}
        {(tooLowProductIds ?? []).length > 0 && (
          <TooLowProducts
            tooLowProductIds={tooLowProductIds}
            tooLowProducts={tooLowProducts}
            allItems={allItems}
            companyName={companyName}
            franchise={franchise}
          />
        )}
        {(excessiveProductIds ?? []).length > 0 && (
          <ExcessiveProducts
            excessiveProductIds={excessiveProductIds}
            excessiveProducts={excessiveProducts}
            allItems={allItems}
            companyName={companyName}
            franchise={franchise}
          />
        )}
        {(boxProductIds ?? []).length > 0 && (
          <BoxProducts
            boxProductIds={boxProductIds}
            boxProducts={boxProducts}
            allItems={allItems}
            companyName={companyName}
            franchise={franchise}
          />
        )}
        <div className="min-h-32 md:min-h-24" />
      </div>

      <DialogFooter className="absolute bottom-0 left-0 right-0 gap-2 p-4 bg-white border-t shadow-xl">
        <Button
          onClick={handleClose}
          className="w-full md:w-auto"
          variant="outline"
        >
          Annuler
        </Button>
        <Button
          onClick={handleConfirm}
          className="bg-green-700 hover:bg-green-800 w-full md:w-auto"
        >
          Valider
        </Button>
      </DialogFooter>
    </div>
  )
}
